import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
// import * as XLSX from "xlsx";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ArticulosMasivaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  // const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  // const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  let anos = new Date();
  anos.setDate(anos.getDate() - 3650);
  const year = anos.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [value, setValue] = useState("");

  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [ano, setAno] = useState("");
  const [observacionesArticulos, setObservacionesArticulos] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      // articulos: "",
      numero: "NA",
      nombre: "NA",
      pzasXcaja: 0,
      EAN: "NA",
      precio: 0,
      costoMX: 0,
      venta: 0,
      linea: "",
      familia: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    try {
          inputFields.map((a) => {
            axios
            .post(
              URL_ARTICULOS,
              {
                codigo: a.numero,
                nombre: a.nombre,
                piezasPorCaja: a.pzasXcaja,
                costoUSD: a.precio,
                costoMX: a.costoMX,
                venta: a.venta,
                observaciones: observacionesArticulos,
                lineas: a.linea,
                marcas: selectedMarca,
                familias: a.familia,
                colecciones: selectedColeccion,
                proveedores: selectedProveedor,
                ano,
                EAN: a.EAN,
                precioEsp: "Si",
                lista1:a.venta,
                lista2:a.venta,
                lista3:a.venta,
                lista4:a.venta,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )

        .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Alta Masiva Articulos",
                        detalle: `${selectedProveedor}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
      }); 
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        // articulos: "",
        numero: "NA",
        nombre: "NA",
        pzasXcaja: 0,
        EAN: "NA",
        precio: 0,
        costoMX: 0,
        venta: 0,
        linea: "",
        familia: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };




  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container col-12">
          <h3 align="center">Alta Masiva Articulos</h3>
          <Form onSubmit={saveArticulos}>
            <Row>
             
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Marca</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedMarca}
                  required
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Marca</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccion}
                  required
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Coleccion</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Año</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={ano}
                  required
                  onChange={(e) => {
                    setAno(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Año</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Observaciones Articulos</Label>
                <Input
                  type="text"
                  placeholder="ObservacionesArticulos"
                  value={observacionesArticulos}
                  required
                  onChange={(e) => {
                    setObservacionesArticulos(e.target.value);
                  }}
                />
              </Col>
            </Row>
           
            {/* Tabla Articulos */}
            <br />
            <Table size="sm" striped borderless className="table-responsive-xl">
              <tr>
                <th>No. Modelo</th>
                <th>Nombre</th>
                <th>Pzs x Caja</th>
                <th>EAN</th>
                <th>FOB</th>
                <th>Costo MX</th>
                <th>Venta</th>
                <th>Linea</th>
                <th>Familia</th>
                <th>Agregar</th>
              </tr>

              {inputFields.map((inputField) => (
                    // <div key={inputField.id}>
              <tbody>
                    <tr>
                      <td>
                        <Input
                          name="numero"
                          type="text"
                          placeholder="numero"
                          value={inputField.numero}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="nombre"
                          type="text"
                          placeholder="nombre"
                          value={inputField.nombre}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="pzasXcaja"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="pzasXcaja"
                          value={inputField.pzasXcaja}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="EAN"
                          type="text"
                          placeholder="EAN"
                          value={inputField.EAN}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="costoMX"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="costoMX"
                          value={inputField.costoMX}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          name="venta"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="venta"
                          value={inputField.venta}
                          required
                          onChange={(event) =>
                            handleChangeInput(inputField.id, event)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          className="col-sm-12"
                          type="select"
                          name="linea"
                          value={inputField.linea}
                          required
                          onChange={(event) => {
                            handleChangeInput(inputField.id, event);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {lineas
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </td>
                      <td>
                        <Input
                          className="col-sm-12"
                          type="select"
                          name="familia"
                          value={inputField.familia}
                          required
                          onChange={(event) => {
                            handleChangeInput(inputField.id, event);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {familias
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </td>
                </tr>
              </tbody>
                  ))}
            </Table>

            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosMasivaCreate;
