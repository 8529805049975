import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
} from "reactstrap";
import Header from "../Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ListadoUsuarios() {
  const { user } = useContext(AuthContext);
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_LISTA_USERS = process.env.REACT_APP_URL_LISTA_USERS;
  const [usuarios, setUsuarios] = useState([]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [activo, setActivo] = useState("");
  const ITEMS_PER_PAGE = 50;

  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const [idEdit, setIdEdit] = useState();
  const [nombre, setNombre] = useState();
  const [apellido, setApellido] = useState();
  const [email, setEmail] = useState();
  const [is_active, setIsActive] = useState();
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");

  const [idPermisos, setIdPermisos] = useState();
  const [menu_areas, setMenuAreas] = useState();
  const [menu_ciclos, setMenuCiclos] = useState();

  const [menu_admin, setMenuAdmin] = useState();
  const [menu_periodos_admin, setMenuPeriodosAdmin] = useState();
  const [menu_departamentos, setMenuDepartamentos] = useState();
  const [menu_presupuestos, setMenuPresupuestos] = useState();
  const [presupuestos_autorizar, setPresupuestosAutorizar] = useState();
  const [presupuestos_create, setPresupuestosCreate] = useState();
  const [menu_proveedores, setMenuProveedores] = useState();
  const [proveedores_create, setProveedoresCreate] = useState();
  const [menu_gastos, setMenuGastos] = useState();
  const [gastos_create, setGastosCreate] = useState();
  const [gastos_autorizar, setGastosAutorizar] = useState();

  const [menu_clientes, setMenuClientes] = useState();
  const [clientes_create, setClientesCreate] = useState();
  const [clientes_edo_cta, setClientesEdoCta] = useState();
  const [menu_catalogos, setMenuCatalogos] = useState();

  const [menu_nominas, setMenuNominas] = useState();
  const [menu_empleados, setMenuEmpleados] = useState();
  const [empleados_create, setEmpleadosCreate] = useState();
  const [empleados_sueldo, setEmpleadosSueldo] = useState();
  const [menu_puestos, setMenuPuestos] = useState();
  const [puestos_create, setPuestosCreate] = useState();
  const [nominas_create, setNominasCreate] = useState();
  const [nominas_listado, setNominasListado] = useState();
  const [nominas_pagos_esp, setNominasPagosEsp] = useState();
  const [nominas_pagar, setNominasPagar] = useState();

  const [menu_reportes, setMenuReportes] = useState();
  const [menu_ingresos, setMenuIngresos] = useState();
  const [menu_egresos, setMenuEgresos] = useState();

  const [menu_usuarios, setMenuUsuarios] = useState();
  const [usuarios_create, setUsuariosCreate] = useState();
  const [usuarios_permisos, setUsuariosPermisos] = useState();

  const [menu_logs, setMenuLogs] = useState();

  const [menu_articulos, setMenuArticulos] = useState();
  const [menu_articulos_foto, setMenuArticulosFoto] = useState();
  const [articulos_create, setArticulosCreate] = useState();
  const [menu_inventarios, setMenuInventarios] = useState();
  const [inventarios, setInventarios] = useState();
  const [menu_traspasos_salida, setMenuTraspasosSalida] = useState();
  const [traspasos_salida_create, setTraspasosSalidaCreate] = useState();
  const [menu_traspasos_entrada, setMenuTraspasosEntrada] = useState();
  const [traspasos_entrada_create, setTraspasosEntradaCreate] = useState();
  const [menu_compras, setMenuCompras] = useState();
  const [menu_oc, setMenuOC] = useState();
  const [oc_create, setOCCreate] = useState();
  const [compras, setCompras] = useState();
  const [compras_create, setComprasCreate] = useState();
  const [menu_ventas, setMenuVentas] = useState();
  const [editar_ventas, setEditarVentas] = useState();
  const [ventas_create, setVentasCreate] = useState();
  const [menu_pedidos, setMenuPedidos] = useState();
  const [pedidos_create, setPedidosCreate] = useState();
  const [precio_menor, setPrecioMenor] = useState();
  const [pedidos_autorizar, setPedidosAutorizar] = useState();
  const [menu_devoluciones, setMenuDevoluciones] = useState();
  const [menu_devolucionesAdmin, setMenuDevolucionesAdmin] = useState();
  const [devoluciones_create, setDevolucionesCreate] = useState();
  const [menu_disponible, setMenuDisponible] = useState();
  const [disponible_vendedores, setDisponibleVendedores] = useState();
  const [surtidos_create, setSurtidosCreate] = useState();
  const [menu_surtidos, setMenuSurtidos] = useState();

  const [proyeccionEgresos, setProyeccionEgresos] = useState();
  const [edoCtaClientes, setEdoCtaClientes] = useState();
  const [proyeccionIngresos, setProyeccionIngresos] = useState();
  const [pagoClientes, setPagoClientes] = useState();
  const [reporteCobranza, setReporteCobranza] = useState();

  const [pendiente_recibir, setPendienteRecibir] = useState();
  const [faltas_empleados, setFaltasEmpleados] = useState();
  const [seguimiento_inventarios, setSeguimientoInventarios] = useState();
  const [grupos, setGrupos] = useState();

  const [abonos, setAbonos] = useState();
  const [abonosCreate, setAbonosCreate] = useState();
  const [notasCargo, setNotasCargo] = useState();
  const [notasCargoCreate, setNotasCargoCreate] = useState();
  const [bonificaciones, setBonificaciones] = useState();
  const [bonificacionesCreate, setBonificacionesCreate] = useState();
  const [comisiones, setComisiones] = useState();
  const [comisionesCreate, setComisionesCreate] = useState();

  const [menuTiendas, setMenuTiendas] = useState();
  const [inventariosTiendas, setInventariosTiendas] = useState();
  const [ventasTiendas, setVentasTiendas] = useState();
  const [listadoVentasTiendas, setListadoVentasTiendas] = useState();

  const [gastosTiendas, setGastosTiendas] = useState();
  const [abonosTiendas, setAbonosTiendas] = useState();
  const [corteTiendas, setCorteTiendas] = useState();
  const [devolucionesTiendas, setDevolucionesTiendas] = useState();
  const [edoCtaTiendas, setEdoCtaTiendas] = useState();

  const [configAdmin, setConfigAdmin] = useState();
  const [bancos, setBancos] = useState();
  const [cambiaPrecios, setCambiaPrecios] = useState();
  const [configuracion, setConfiguracion] = useState();

  const [menuCalidad, setMenuCalidad] = useState();
  const [calidadCantidad, setCalidadCantidad] = useState();
  const [calidadImportes, setCalidadImportes] = useState();

  const [menuAnticipos, setMenuAnticipos] = useState();
  const [anticiposCreate, setAnticiposCreate] = useState();

  const [muestrasEntrada, setMuestrasEntrada] = useState();
  const [muestrasEntradaCreate, setMuestrasEntradaCreate] = useState();
  const [muestrasSalida, setMuestrasSalida] = useState();
  const [muestrasSalidaCreate, setMuestrasSalidaCreate] = useState();
  const [entregarNota, setEntregarNota] = useState();
  const [ajustesInventario, setAjustesInventario] = useState();
  const [autorizarAjustesInventario, setAutorizarAjustesInventario] =
    useState();

  const [abonosProveedores, setAbonosProveedores] = useState();
  const [abonosProveedoresCreate, setAbonosProveedoresCreate] = useState();
  const [pagoProveedores, setPagoProveedores] = useState();
  const [pagoProveedoresCreate, setPagoProveedoresCreate] = useState();
  const [edoCtaProveedores, setEdoCtaProveedores] = useState();
  const [menu_cadenas, setMenuCadenas] = useState();

  const [emailSis, setEmailSis] = useState("admin@sistemify.com");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [modalPermisos, setModalPermisos] = useState(false);
  const togglePermisos = () => setModalPermisos(!modalPermisos);

  useEffect(() => {
    axios
      .get(URL_LISTA_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        let arrayTabla = allUsuarios
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              nombre: a.nombre,
              apellido: a.apellido,
              email: a.email,
              is_active: a.is_active,
              menu_edoCta_Cliente: a.menu_edoCta_Cliente,
              menu_areas: a.menu_areas,
              menu_ciclos: a.menu_ciclos,
              menu_admin: a.menu_admin,
              menu_periodos_admin: a.menu_periodos_admin,
              menu_departamentos: a.menu_departamentos,
              menu_presupuestos: a.menu_presupuestos,
              presupuestos_autorizar: a.presupuestos_autorizar,
              presupuestos_create: a.presupuestos_create,
              menu_proveedores: a.menu_proveedores,
              proveedores_create: a.proveedores_create,
              menu_gastos: a.menu_gastos,
              gastos_create: a.gastos_create,
              gastos_autorizar: a.gastos_autorizar,
              menu_clientes: a.menu_clientes,
              clientes_create: a.clientes_create,
              clientes_edo_cta: a.clientes_edo_cta,
              menu_catalogos: a.menu_catalogos,
              menu_nominas: a.menu_nominas,
              menu_empleados: a.menu_empleados,
              empleados_create: a.empleados_create,
              empleados_sueldo: a.empleados_sueldo,
              menu_puestos: a.menu_puestos,
              puestos_create: a.puestos_create,
              nominas_create: a.nominas_create,
              nominas_listado: a.nominas_listado,
              nominas_pagos_esp: a.nominas_pagos_esp,
              nominas_pagar: a.nominas_pagar,
              menu_reportes: a.menu_reportes,
              menu_ingresos: a.menu_ingresos,
              menu_egresos: a.menu_egresos,
              menu_usuarios: a.menu_usuarios,
              usuarios_create: a.usuarios_create,
              usuarios_permisos: a.usuarios_permisos,
              menu_logs: a.menu_logs,
              menu_cobradores: a.menu_cobradores,
              menu_articulos: a.menu_articulos,
              menu_articulos_foto: a.menu_articulos_foto,
              articulos_create: a.articulos_create,
              menu_inventarios: a.menu_inventarios,
              inventarios: a.inventarios,
              menu_traspasos_salida: a.menu_traspasos_salida,
              traspasos_salida_create: a.traspasos_salida_create,
              menu_traspasos_entrada: a.menu_traspasos_entrada,
              traspasos_entrada_create: a.traspasos_entrada_create,
              menu_compras: a.menu_compras,
              menu_oc: a.menu_oc,
              oc_create: a.oc_create,
              compras: a.compras,
              compras_create: a.compras_create,
              menu_ventas: a.menu_ventas,
              editar_ventas: a.editar_ventas,
              ventas_create: a.ventas_create,
              menu_pedidos: a.menu_pedidos,
              pedidos_create: a.pedidos_create,
              precio_menor: a.precio_menor,
              pedidos_autorizar: a.pedidos_autorizar,
              menu_devoluciones: a.menu_devoluciones,
              menu_devolucionesAdmin: a.menu_devolucionesAdmin,
              devoluciones_create: a.devoluciones_create,
              menu_disponible: a.menu_disponible,
              disponible_vendedores: a.disponible_vendedores,
              menu_surtidos: a.menu_surtidos,
              surtidos_create: a.surtidos_create,
              pendiente_recibir: a.pendiente_recibir,
              faltas_empleados: a.faltas_empleados,
              seguimiento_inventarios: a.seguimiento_inventarios,
              grupos: a.grupos,
              proyeccionEgresos: a.proyeccionEgresos,
              edoCtaClientes: a.edoCtaClientes,
              proyeccionIngresos: a.proyeccionIngresos,
              pagoClientes: a.pagoClientes,
              reporteCobranza: a.reporteCobranza,
              menuTiendas: a.menuTiendas,
              inventariosTiendas: a.inventariosTiendas,
              ventasTiendas: a.ventasTiendas,
              listadoVentasTiendas: a.listadoVentasTiendas,
              gastosTiendas: a.gastosTiendas,
              corteTiendas: a.corteTiendas,
              abonosTiendas: a.abonosTiendas,
              devolucionesTiendas: a.devolucionesTiendas,
              edoCtaTiendas: a.edoCtaTiendas,
              configAdmin: a.configAdmin,
              configuracion: a.configuracion,
              bancos: a.bancos,
              cambiaPrecios: a.cambiaPrecios,
              menuCalidad: a.menuCalidad,
              calidadCantidad: a.calidadCantidad,
              calidadImportes: a.calidadImportes,
              menuAnticipos: a.menuAnticipos,
              anticiposCreate: a.anticiposCreate,
              abonos: a.abonos,
              abonosCreate: a.abonosCreate,
              notasCargo: a.notasCargo,
              notasCargoCreate: a.notasCargoCreate,
              bonificaciones: a.bonificaciones,
              bonificacionesCreate: a.bonificacionesCreate,
              comisiones: a.comisiones,
              comisionesCreate: a.comisionesCreate,
              muestrasEntrada: a.muestrasEntrada,
              muestrasEntradaCreate: a.muestrasEntradaCreate,
              muestrasSalida: a.muestrasSalida,
              muestrasSalidaCreate: a.muestrasSalidaCreate,
              entregarNota: a.entregarNota,
              abonosProveedores: a.abonosProveedores,
              abonosProveedoresCreate: a.abonosProveedoresCreate,
              pagoProveedores: a.pagoProveedores,
              pagoProveedoresCreate: a.pagoProveedoresCreate,
              edoCtaProveedores: a.edoCtaProveedores,
              ajustesInventario: a.ajustesInventario,
              autorizarAjustesInventario: a.autorizarAjustesInventario,
              menu_cadenas: a.menu_cadenas,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setUsuarios(allUsuarios);
      })
      .catch((err) => {
        console.log(err);
      });
   
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Apellido", field: "apellido", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(activo)
      );
    }

    if (emailSis) {
      computedComments = computedComments.filter(
        (e) => !e.email.includes(emailSis)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting, activo, email]);

  function Edit(idEdit, nombre, apellido, email) {
    axios
      .get(`${URL_USERS}/${idEdit}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let usuarioEdit = response.data;
        setArea(usuarioEdit.areas[0]._id);
      });
    setIdEdit(idEdit);
    setNombre(nombre);
    setApellido(apellido);
    setEmail(email);
    toggle();
  }

  function editUsuario(event) {
    event.preventDefault();
    axios
      .patch(
        `${URL_USERS}/${idEdit}`,
        {
          nombre,
          apellido,
          email,
          areas: area,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function Permisos(
    id,
    nombre,
    apellido,
    menu_areas,
    menu_ciclos,
    menu_admin,
    menu_periodos_admin,
    menu_departamentos,
    menu_presupuestos,
    presupuestos_autorizar,
    presupuestos_create,
    menu_proveedores,
    proveedores_create,
    menu_gastos,
    gastos_create,
    gastos_autorizar,
    menu_clientes,
    clientes_create,
    clientes_edo_cta,
    menu_catalogos,
    menu_nominas,
    menu_empleados,
    empleados_create,
    empleados_sueldo,
    menu_puestos,
    puestos_create,
    nominas_create,
    nominas_listado,
    nominas_pagos_esp,
    nominas_pagar,
    menu_reportes,
    menu_ingresos,
    menu_egresos,
    menu_usuarios,
    usuarios_create,
    usuarios_permisos,
    menu_logs,
    menu_articulos,
    menu_articulos_foto,
    articulos_create,
    menu_inventarios,
    inventarios,
    menu_traspasos_salida,
    traspasos_salida_create,
    menu_traspasos_entrada,
    traspasos_entrada_create,
    menu_compras,
    menu_oc,
    oc_create,
    compras,
    compras_create,
    menu_ventas,
    editar_ventas,
    ventas_create,
    menu_pedidos,
    pedidos_create,
    precio_menor,
    pedidos_autorizar,
    menu_devoluciones,
    menu_devolucionesAdmin,
    devoluciones_create,
    menu_disponible,
    disponible_vendedores,
    menu_surtidos,
    surtidos_create,
    pendiente_recibir,
    faltas_empleados,
    seguimiento_inventarios,
    grupos,
    proyeccionEgresos,
    edoCtaClientes,
    proyeccionIngresos,
    pagoClientes,
    reporteCobranza,
    menuTiendas,
    inventariosTiendas,
    ventasTiendas,
    listadoVentasTiendas,
    gastosTiendas,
    corteTiendas,
    abonosTiendas,
    devolucionesTiendas,
    edoCtaTiendas,
    configAdmin,
    configuracion,
    bancos,
    cambiaPrecios,
    menuCalidad,
    calidadCantidad,
    calidadImportes,
    menuAnticipos,
    anticiposCreate,
    abonos,
    abonosCreate,
    notasCargo,
    notasCargoCreate,
    bonificaciones,
    bonificacionesCreate,
    comisiones,
    comisionesCreate,
    muestrasEntrada,
    muestrasEntradaCreate,
    muestrasSalida,
    muestrasSalidaCreate,
    entregarNota,
    abonosProveedores,
    abonosProveedoresCreate,
    pagoProveedores,
    pagoProveedoresCreate,
    edoCtaProveedores,
    ajustesInventario,
    autorizarAjustesInventario,
    menu_cadenas,
  ) {
    setIdPermisos(id);
    setNombre(nombre);
    setApellido(apellido);
    setMenuAdmin(menu_admin);
    setMenuNominas(menu_nominas);
    setMenuReportes(menu_reportes);
    setMenuUsuarios(menu_usuarios);
    setMenuAreas(menu_areas);
    setMenuCiclos(menu_ciclos);
    setMenuPeriodosAdmin(menu_periodos_admin);
    setMenuDepartamentos(menu_departamentos);
    setMenuPresupuestos(menu_presupuestos);
    setPresupuestosAutorizar(presupuestos_autorizar);
    setPresupuestosCreate(presupuestos_create);
    setMenuProveedores(menu_proveedores);
    setMenuGastos(menu_gastos);
    setMenuClientes(menu_clientes);
    setMenuCatalogos(menu_catalogos);
    setMenuEmpleados(menu_empleados);
    setMenuPuestos(menu_puestos);
    setMenuIngresos(menu_ingresos);
    setMenuEgresos(menu_egresos);
    setNominasCreate(nominas_create);
    setProveedoresCreate(proveedores_create);
    setGastosCreate(gastos_create);
    setGastosAutorizar(gastos_autorizar);
    setClientesCreate(clientes_create);
    setClientesEdoCta(clientes_edo_cta);
    setEmpleadosCreate(empleados_create);
    setEmpleadosSueldo(empleados_sueldo);
    setPuestosCreate(puestos_create);
    setNominasListado(nominas_listado);
    setNominasPagosEsp(nominas_pagos_esp);
    setNominasPagar(nominas_pagar);
    setUsuariosCreate(usuarios_create);
    setUsuariosPermisos(usuarios_permisos);
    setMenuLogs(menu_logs);
    setMenuArticulos(menu_articulos);
    setMenuArticulosFoto(menu_articulos_foto);
    setArticulosCreate(articulos_create);
    setMenuInventarios(menu_inventarios);
    setInventarios(inventarios);
    setMenuTraspasosSalida(menu_traspasos_salida);
    setTraspasosSalidaCreate(traspasos_salida_create);
    setMenuTraspasosEntrada(menu_traspasos_entrada);
    setTraspasosEntradaCreate(traspasos_entrada_create);
    setMenuCompras(menu_compras);
    setMenuOC(menu_oc);
    setOCCreate(oc_create);
    setCompras(compras);
    setComprasCreate(compras_create);
    setMenuVentas(menu_ventas);
    setEditarVentas(editar_ventas);
    setVentasCreate(ventas_create);
    setMenuPedidos(menu_pedidos);
    setPedidosCreate(pedidos_create);
    setPrecioMenor(precio_menor);
    setPedidosAutorizar(pedidos_autorizar);
    setMenuDevoluciones(menu_devoluciones);
    setMenuDevolucionesAdmin(menu_devolucionesAdmin);
    setDevolucionesCreate(devoluciones_create);
    setMenuDisponible(menu_disponible);
    setDisponibleVendedores(disponible_vendedores);
    setMenuSurtidos(menu_surtidos);
    setSurtidosCreate(surtidos_create);
    setPendienteRecibir(pendiente_recibir);
    setFaltasEmpleados(faltas_empleados);
    setSeguimientoInventarios(seguimiento_inventarios);
    setGrupos(grupos);
    setProyeccionEgresos(proyeccionEgresos);
    setEdoCtaClientes(edoCtaClientes);
    setProyeccionIngresos(proyeccionIngresos);
    setPagoClientes(pagoClientes);
    setReporteCobranza(reporteCobranza);
    setMenuTiendas(menuTiendas);
    setInventariosTiendas(inventariosTiendas);
    setVentasTiendas(ventasTiendas);
    setListadoVentasTiendas(listadoVentasTiendas);
    setGastosTiendas(gastosTiendas);
    setCorteTiendas(corteTiendas);
    setAbonosTiendas(abonosTiendas);
    setDevolucionesTiendas(devolucionesTiendas);
    setEdoCtaTiendas(edoCtaTiendas);
    setCambiaPrecios(cambiaPrecios);
    setBancos(bancos);
    setConfigAdmin(configAdmin);
    setConfiguracion(configuracion);
    setMenuCalidad(menuCalidad);
    setCalidadCantidad(calidadCantidad);
    setCalidadImportes(calidadImportes);
    setMenuAnticipos(menuAnticipos);
    setAnticiposCreate(anticiposCreate);
    setAbonos(abonos);
    setAbonosCreate(abonosCreate);
    setNotasCargo(notasCargo);
    setNotasCargoCreate(notasCargoCreate);
    setBonificaciones(bonificaciones);
    setBonificacionesCreate(bonificacionesCreate);
    setComisiones(comisiones);
    setComisionesCreate(comisionesCreate);
    setMuestrasEntrada(muestrasEntrada);
    setMuestrasEntradaCreate(muestrasEntradaCreate);
    setMuestrasSalida(muestrasSalida);
    setMuestrasSalidaCreate(muestrasSalidaCreate);
    setEntregarNota(entregarNota);
    setAbonosProveedores(abonosProveedores);
    setAbonosProveedoresCreate(abonosProveedoresCreate);
    setPagoProveedores(pagoProveedores);
    setPagoProveedoresCreate(pagoProveedoresCreate);
    setEdoCtaProveedores(edoCtaProveedores);
    setAjustesInventario(ajustesInventario);
    setAutorizarAjustesInventario(autorizarAjustesInventario);
    setMenuCadenas(menu_cadenas)
    togglePermisos();
  }

  function permisosUsuario(event) {
    event.preventDefault();
    axios
      .patch(
        `${URL_USERS}/${idPermisos}`,
        {
          menu_areas,
          menu_ciclos,
          menu_admin,
          menu_periodos_admin,
          menu_departamentos,
          menu_presupuestos,
          presupuestos_autorizar,
          presupuestos_create,
          menu_proveedores,
          proveedores_create,
          menu_gastos,
          gastos_create,
          gastos_autorizar,
          menu_clientes,
          clientes_create,
          clientes_edo_cta,
          menu_catalogos,
          menu_nominas,
          menu_empleados,
          empleados_create,
          empleados_sueldo,
          menu_puestos,
          puestos_create,
          nominas_create,
          nominas_listado,
          nominas_pagos_esp,
          nominas_pagar,
          menu_reportes,
          menu_ingresos,
          menu_egresos,
          menu_usuarios,
          usuarios_create,
          usuarios_permisos,
          menu_logs,
          menu_articulos,
          menu_articulos_foto,
          articulos_create,
          menu_inventarios,
          inventarios,
          menu_traspasos_salida,
          traspasos_salida_create,
          menu_traspasos_entrada,
          traspasos_entrada_create,
          menu_compras,
          menu_oc,
          oc_create,
          compras,
          compras_create,
          menu_ventas,
          editar_ventas,
          ventas_create,
          menu_pedidos,
          pedidos_create,
          precio_menor,
          pedidos_autorizar,
          menu_devoluciones,
          menu_devolucionesAdmin,
          devoluciones_create,
          menu_disponible,
          disponible_vendedores,
          menu_surtidos,
          surtidos_create,
          pendiente_recibir,
          faltas_empleados,
          seguimiento_inventarios,
          grupos,
          proyeccionEgresos,
          edoCtaClientes,
          proyeccionIngresos,
          pagoClientes,
          reporteCobranza,
          menuTiendas,
          inventariosTiendas,
          ventasTiendas,
          listadoVentasTiendas,
          gastosTiendas,
          corteTiendas,
          abonosTiendas,
          devolucionesTiendas,
          edoCtaTiendas,
          configAdmin,
          configuracion,
          bancos,
          cambiaPrecios,
          menuCalidad,
          calidadCantidad,
          calidadImportes,
          menuAnticipos,
          anticiposCreate,
          abonos,
          abonosCreate,
          notasCargo,
          notasCargoCreate,
          bonificaciones,
          bonificacionesCreate,
          comisiones,
          comisionesCreate,
          muestrasEntrada,
          muestrasEntradaCreate,
          muestrasSalida,
          muestrasSalidaCreate,
          entregarNota,
          abonosProveedores,
          abonosProveedoresCreate,
          pagoProveedores,
          pagoProveedoresCreate,
          edoCtaProveedores,
          ajustesInventario,
          autorizarAjustesInventario,
          menu_cadenas,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        togglePermisos();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <br />
      {user.menu_usuarios ? (
        <div>
          {
            <div className="card container col-11">
              <br />
                  <h3 align="center">Usuarios</h3>
                  <div className="row">
                    <div className="col-md-9">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                    <div className="col-md-2 d-flex flex-row-reverse">
                      <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                    </div>
                  <Table
                    size="sm"
                    striped
                    borderless
                    className="table-responsive-xl"
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {commentsData.map((p) => {
                        // if (p.menu_edoCta_Fam == false && p.menu_edoCta_Cliente == false && p.email != "admin@sistemify.com") {
                        return (
                          <tr>
                            <td>{p.is_active}</td>
                            <td>{p.nombre}</td>
                            <td>{p.apellido}</td>
                            <td>{p.email}</td>
                            <td>
                              {/* {user.usuarios_create ? (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    Edit(p._id, p.nombre, p.apellido, p.email);
                                  }}
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              )} */}
                              {user.usuarios_permisos &&
                              user.is_active == "Si" ? (
                                <Button
                                  color="success"
                                  id="Permisos"
                                  size="sm"
                                  onClick={(e) => {
                                    Permisos(
                                      p._id,
                                      p.nombre,
                                      p.apellido,
                                      p.menu_areas,
                                      p.menu_ciclos,
                                      p.menu_admin,
                                      p.menu_periodos_admin,
                                      p.menu_departamentos,
                                      p.menu_presupuestos,
                                      p.presupuestos_autorizar,
                                      p.presupuestos_create,
                                      p.menu_proveedores,
                                      p.proveedores_create,
                                      p.menu_gastos,
                                      p.gastos_create,
                                      p.gastos_autorizar,
                                      p.menu_clientes,
                                      p.clientes_create,
                                      p.clientes_edo_cta,
                                      p.menu_catalogos,
                                      p.menu_nominas,
                                      p.menu_empleados,
                                      p.empleados_create,
                                      p.empleados_sueldo,
                                      p.menu_puestos,
                                      p.puestos_create,
                                      p.nominas_create,
                                      p.nominas_listado,
                                      p.nominas_pagos_esp,
                                      p.nominas_pagar,
                                      p.menu_reportes,
                                      p.menu_ingresos,
                                      p.menu_egresos,
                                      p.menu_usuarios,
                                      p.usuarios_create,
                                      p.usuarios_permisos,
                                      p.menu_logs,
                                      p.menu_articulos,
                                      p.menu_articulos_foto,
                                      p.articulos_create,
                                      p.menu_inventarios,
                                      p.inventarios,
                                      p.menu_traspasos_salida,
                                      p.traspasos_salida_create,
                                      p.menu_traspasos_entrada,
                                      p.traspasos_entrada_create,
                                      p.menu_compras,
                                      p.menu_oc,
                                      p.oc_create,
                                      p.compras,
                                      p.compras_create,
                                      p.menu_ventas,
                                      p.editar_ventas,
                                      p.ventas_create,
                                      p.menu_pedidos,
                                      p.pedidos_create,
                                      p.precio_menor,
                                      p.pedidos_autorizar,
                                      p.menu_devoluciones,
                                      p.menu_devolucionesAdmin,
                                      p.devoluciones_create,
                                      p.menu_disponible,
                                      p.disponible_vendedores,
                                      p.menu_surtidos,
                                      p.surtidos_create,
                                      p.pendiente_recibir,
                                      p.faltas_empleados,
                                      p.seguimiento_inventarios,
                                      p.grupos,
                                      p.proyeccionEgresos,
                                      p.edoCtaClientes,
                                      p.proyeccionIngresos,
                                      p.pagoClientes,
                                      p.reporteCobranza,
                                      p.menuTiendas,
                                      p.inventariosTiendas,
                                      p.ventasTiendas,
                                      p.listadoVentasTiendas,
                                      p.gastosTiendas,
                                      p.corteTiendas,
                                      p.abonosTiendas,
                                      p.devolucionesTiendas,
                                      p.edoCtaTiendas,
                                      p.configAdmin,
                                      p.configuracion,
                                      p.bancos,
                                      p.cambiaPrecios,
                                      p.menuCalidad,
                                      p.calidadCantidad,
                                      p.calidadImportes,
                                      p.menuAnticipos,
                                      p.anticiposCreate,
                                      p.abonos,
                                      p.abonosCreate,
                                      p.notasCargo,
                                      p.notasCargoCreate,
                                      p.bonificaciones,
                                      p.bonificacionesCreate,
                                      p.comisiones,
                                      p.comisionesCreate,
                                      p.muestrasEntrada,
                                      p.muestrasEntradaCreate,
                                      p.muestrasSalida,
                                      p.muestrasSalidaCreate,
                                      p.entregarNota,
                                      p.abonosProveedores,
                                      p.abonosProveedoresCreate,
                                      p.pagoProveedores,
                                      p.pagoProveedoresCreate,
                                      p.edoCtaProveedores,
                                      p.ajustesInventario,
                                      p.autorizarAjustesInventario,
                                      p.menu_cadenas,
                                    );
                                  }}
                                >
                                  <i class="fas fa-key"></i>{" "}
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Permisos"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-key"></i>{" "}
                                </Button>
                              )}
                              {user.usuarios_create ? (
                                <Baja
                                  idStatus={p._id}
                                  is_active={p.is_active}
                                  URL_BAJA={process.env.REACT_APP_URL_USERS}
                                />
                              ) : undefined}
                            </td>
                          </tr>
                        );
                        // }
                      })}
                    </tbody>
                  </Table>
            </div>
          }
        </div>
      ) : undefined}

      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>
            Editar Usuario {nombre} {apellido}{" "}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombre}
            required
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Apellido</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={apellido}
            required
            onChange={(e) => {
              setApellido(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={email}
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
         
          <Label className="mr-sm-2">Almacen</Label>
          <Input
            type="select"
            value={area}
            required
            onChange={(e) => setArea(e.target.value)}
          >
            <option value="0">Selecciona</option>
            {areas
               .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((a) => {
                return <option value={a._id}>{a.name}</option>;
              })}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editUsuario}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {/* //Permisos */}
      <Modal size="xxl" isOpen={modalPermisos} toggle={togglePermisos}>
        <ModalHeader toggle={togglePermisos}>
          <h4>
            Permisos de {nombre} {apellido}{" "}
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className="card">
            <br />
            <Row>
              <Col md={1}></Col>
              <Col md={2}>
                <h5>CATALOGOS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuCatalogos(event.currentTarget.checked)
                  }
                  checked={menu_catalogos}
                />
                <h5 className="subMenuTitulo">Catalogos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuArticulos(event.currentTarget.checked)
                  }
                  checked={menu_articulos}
                />
                <h5 className="subMenuTitulo">Articulos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setArticulosCreate(event.currentTarget.checked)
                  }
                  checked={articulos_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Articulos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuArticulosFoto(event.currentTarget.checked)
                  }
                  checked={menu_articulos_foto}
                />
                <h5 className="subMenuTitulo">Fotos Articulos</h5>

              </Col>
              
              <Col md={2}>
                <h5>COMPRAS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuProveedores(event.currentTarget.checked)
                  }
                  checked={menu_proveedores}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Proveedores</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setProveedoresCreate(event.currentTarget.checked)
                  }
                  checked={proveedores_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Proveedores</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuCompras(event.currentTarget.checked)
                  }
                  checked={menu_compras}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Compras</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setComprasCreate(event.currentTarget.checked)
                  }
                  checked={compras_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Compras</h5>
                <Input
                  type="checkbox"
                  onChange={(event) => setMenuOC(event.currentTarget.checked)}
                  checked={menu_oc}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Ordenes de Produccion</h5>
                <Input
                  type="checkbox"
                  onChange={(event) => setOCCreate(event.currentTarget.checked)}
                  checked={oc_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Ordenes de Produccion</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setProyeccionEgresos(event.currentTarget.checked)
                  }
                  checked={proyeccionEgresos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Proyeccion Egresos</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuCalidad(event.currentTarget.checked)
                  }
                  checked={menuCalidad}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Control Calidad</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setCalidadCantidad(event.currentTarget.checked)
                  }
                  checked={calidadCantidad}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Control Calidad Cantidades</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setCalidadImportes(event.currentTarget.checked)
                  }
                  checked={calidadImportes}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Control Calidad Importes</h5>
              </Col>

              <Col md={2}>
                <h5>VENTAS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuVentas(event.currentTarget.checked)
                  }
                  checked={menu_ventas}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Ventas</h5>
                {/* <Input
        type="checkbox"
        onChange={(event) => setVentasCreate(event.currentTarget.checked)}
        checked={ventas_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Ventas</h5> */}
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setCambiaPrecios(event.currentTarget.checked)
                  }
                  checked={cambiaPrecios}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Cambiar Precios</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEditarVentas(event.currentTarget.checked)
                  }
                  checked={editar_ventas}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Cancelar Ventas</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuClientes(event.currentTarget.checked)
                  }
                  checked={menu_clientes}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Clientes</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setClientesCreate(event.currentTarget.checked)
                  }
                  checked={clientes_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Clientes</h5>
                <Input
                  type="checkbox"
                  onChange={(event) => setGrupos(event.currentTarget.checked)}
                  checked={grupos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Grupos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuPedidos(event.currentTarget.checked)
                  }
                  checked={menu_pedidos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Pedidos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPedidosCreate(event.currentTarget.checked)
                  }
                  checked={pedidos_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Pedidos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPrecioMenor(event.currentTarget.checked)
                  }
                  checked={precio_menor}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Precio Menor</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPedidosAutorizar(event.currentTarget.checked)
                  }
                  checked={pedidos_autorizar}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Autorizar Pedidos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuSurtidos(event.currentTarget.checked)
                  }
                  checked={menu_surtidos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Notas</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setSurtidosCreate(event.currentTarget.checked)
                  }
                  checked={surtidos_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Notas</h5>
              
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuDevoluciones(event.currentTarget.checked)
                  }
                  checked={menu_devoluciones}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Devoluciones</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setDevolucionesCreate(event.currentTarget.checked)
                  }
                  checked={devoluciones_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Devoluciones</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEdoCtaClientes(event.currentTarget.checked)
                  }
                  checked={edoCtaClientes}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Estado Cuenta Clientes</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPagoClientes(event.currentTarget.checked)
                  }
                  checked={pagoClientes}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Pago Clientes</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setProyeccionIngresos(event.currentTarget.checked)
                  }
                  checked={proyeccionIngresos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Proyeccion Ingresos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setReporteCobranza(event.currentTarget.checked)
                  }
                  checked={reporteCobranza}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Reporte Cobranza</h5>
              </Col>

              <Col md={2}>
                <h5>TIENDAS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuTiendas(event.currentTarget.checked)
                  }
                  checked={menuTiendas}
                />
                <h5 className="subMenuTitulo">Menu Tiendas</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setInventariosTiendas(event.currentTarget.checked)
                  }
                  checked={inventariosTiendas}
                />
                <h5 className="subMenuTitulo">Inventarios Tiendas</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setVentasTiendas(event.currentTarget.checked)
                  }
                  checked={ventasTiendas}
                />
                <h5 className="subMenuTitulo">Ventas de Tiendas</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEntregarNota(event.currentTarget.checked)
                  }
                  checked={entregarNota}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Entregar Nota</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setListadoVentasTiendas(event.currentTarget.checked)
                  }
                  checked={listadoVentasTiendas}
                />
                <h5 className="subMenuTitulo">Reporte de Ventas</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setGastosTiendas(event.currentTarget.checked)
                  }
                  checked={gastosTiendas}
                />
                <h5 className="subMenuTitulo">Gastos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setCorteTiendas(event.currentTarget.checked)
                  }
                  checked={corteTiendas}
                />
                <h5 className="subMenuTitulo">Corte Diario</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setAbonosTiendas(event.currentTarget.checked)
                  }
                  checked={abonosTiendas}
                />
                <h5 className="subMenuTitulo">Abonos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setDevolucionesTiendas(event.currentTarget.checked)
                  }
                  checked={devolucionesTiendas}
                />
                <h5 className="subMenuTitulo">Devoluciones</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEdoCtaTiendas(event.currentTarget.checked)
                  }
                  checked={edoCtaTiendas}
                />
                <h5 className="subMenuTitulo">Estado Cuenta Tiendas</h5>
              </Col>

              <Col md={2}>
                <h5>INVENTARIO</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuInventarios(event.currentTarget.checked)
                  }
                  checked={menu_inventarios}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Inventarios</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setInventarios(event.currentTarget.checked)
                  }
                  checked={inventarios}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Inventarios</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuAreas(event.currentTarget.checked)
                  }
                  checked={menu_areas}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Almacenes</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuDisponible(event.currentTarget.checked)
                  }
                  checked={menu_disponible}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Disponible</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setDisponibleVendedores(event.currentTarget.checked)
                  }
                  checked={disponible_vendedores}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Disponible Vendedores</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setSeguimientoInventarios(event.currentTarget.checked)
                  }
                  checked={seguimiento_inventarios}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Seguimiento Inventarios</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPendienteRecibir(event.currentTarget.checked)
                  }
                  checked={pendiente_recibir}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Pendiente Recibir</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuTraspasosSalida(event.currentTarget.checked)
                  }
                  checked={menu_traspasos_salida}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Traspasos Salida</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setTraspasosSalidaCreate(event.currentTarget.checked)
                  }
                  checked={traspasos_salida_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Traspasos Salida</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuTraspasosEntrada(event.currentTarget.checked)
                  }
                  checked={menu_traspasos_entrada}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Traspasos Entrada</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setTraspasosEntradaCreate(event.currentTarget.checked)
                  }
                  checked={traspasos_entrada_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Traspasos Entrada</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMuestrasEntrada(event.currentTarget.checked)
                  }
                  checked={muestrasEntrada}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Entrada de Muestras</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMuestrasEntradaCreate(event.currentTarget.checked)
                  }
                  checked={muestrasEntradaCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Entrada de Muestras</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMuestrasSalida(event.currentTarget.checked)
                  }
                  checked={muestrasSalida}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Salida de Muestras</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMuestrasSalidaCreate(event.currentTarget.checked)
                  }
                  checked={muestrasSalidaCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Salida de Muestras</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setAjustesInventario(event.currentTarget.checked)
                  }
                  checked={ajustesInventario}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Ajuste Inventario</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setAutorizarAjustesInventario(event.currentTarget.checked)
                  }
                  checked={autorizarAjustesInventario}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Autorizar Ajuste Inventario</h5>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md={1}></Col>
              <Col md={2}>
                <h5>GASTOS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuGastos(event.currentTarget.checked)
                  }
                  checked={menu_gastos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Gastos</h5>
                {/* <Input
        type="checkbox"
        onChange={(event) => setMenuPresupuestos(event.currentTarget.checked)}
        checked={menu_presupuestos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Presupuestos</h5>
       <Input
        type="checkbox"
        onChange={(event) => setPresupuestosCreate(event.currentTarget.checked)}
        checked={presupuestos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Presupuestos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setPresupuestosAutorizar(event.currentTarget.checked)}
        checked={presupuestos_autorizar}
        className="subMenu"
      /><h5 className="subMenuTitulo">Autorizar Presupuestos</h5> */}

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuGastos(event.currentTarget.checked)
                  }
                  checked={menu_gastos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Gastos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setGastosCreate(event.currentTarget.checked)
                  }
                  checked={gastos_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Gastos</h5>
                {/* <Input
        type="checkbox"
        onChange={(event) => setGastosAutorizar(event.currentTarget.checked)}
        checked={gastos_autorizar}
        className="subMenu"
      /><h5 className="subMenuTitulo">Autorizar Gastos</h5>

    <Input
        type="checkbox"
        onChange={(event) => setMenuCiclos(event.currentTarget.checked)}
        checked={menu_ciclos}
      /><h5 className="subMenuTitulo">Ciclos</h5>
       
       <Input
        type="checkbox"
        onChange={(event) => setMenuPeriodosAdmin(event.currentTarget.checked)}
        checked={menu_periodos_admin}
        className="subMenu"
      /><h5 className="subMenuTitulo">Periodos</h5>

    <Input
        type="checkbox"
        onChange={(event) => setMenuDepartamentos(event.currentTarget.checked)}
        checked={menu_departamentos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Departamentos</h5>*/}
              </Col>

              <Col md={2}>
                <h5>EMPLEADOS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuNominas(event.currentTarget.checked)
                  }
                  checked={menu_nominas}
                />
                <h5 className="subMenuTitulo">Menu Empleados</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuEmpleados(event.currentTarget.checked)
                  }
                  checked={menu_empleados}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Empleados</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEmpleadosCreate(event.currentTarget.checked)
                  }
                  checked={empleados_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Empleados</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEmpleadosSueldo(event.currentTarget.checked)
                  }
                  checked={empleados_sueldo}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Sueldo Empleados</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setFaltasEmpleados(event.currentTarget.checked)
                  }
                  checked={faltas_empleados}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Faltas Empleados</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuPuestos(event.currentTarget.checked)
                  }
                  checked={menu_puestos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Puestos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPuestosCreate(event.currentTarget.checked)
                  }
                  checked={puestos_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Puestos</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setNominasListado(event.currentTarget.checked)
                  }
                  checked={nominas_listado}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Nominas</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setNominasCreate(event.currentTarget.checked)
                  }
                  checked={nominas_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Nominas</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setNominasPagar(event.currentTarget.checked)
                  }
                  checked={nominas_pagar}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Pagar Nominas</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setNominasPagosEsp(event.currentTarget.checked)
                  }
                  checked={nominas_pagos_esp}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Pagos Especiales</h5>
              </Col>

              <Col md={2}>
                <h5>ADMIN</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuAdmin(event.currentTarget.checked)
                  }
                  checked={menu_admin}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Admin</h5>
                <Input
                  type="checkbox"
                  onChange={(event) => setBancos(event.currentTarget.checked)}
                  checked={bancos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Bancos</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuAnticipos(event.currentTarget.checked)
                  }
                  checked={menuAnticipos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Anticipos</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setAnticiposCreate(event.currentTarget.checked)
                  }
                  checked={anticiposCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Anticipos</h5>

                <Input
                  type="checkbox"
                  onChange={(event) => setAbonos(event.currentTarget.checked)}
                  checked={abonos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Abonos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setAbonosCreate(event.currentTarget.checked)
                  }
                  checked={abonosCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Abonos</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setNotasCargo(event.currentTarget.checked)
                  }
                  checked={notasCargo}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Notas de Cargo</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setNotasCargoCreate(event.currentTarget.checked)
                  }
                  checked={notasCargoCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Notas de Cargo</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuDevolucionesAdmin(event.currentTarget.checked)
                  }
                  checked={menu_devolucionesAdmin}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Devoluciones Admin</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setBonificaciones(event.currentTarget.checked)
                  }
                  checked={bonificaciones}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Bonificaciones</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setBonificacionesCreate(event.currentTarget.checked)
                  }
                  checked={bonificacionesCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Bonificaciones</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setComisiones(event.currentTarget.checked)
                  }
                  checked={comisiones}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Comisiones</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setComisionesCreate(event.currentTarget.checked)
                  }
                  checked={comisionesCreate}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Comisiones</h5>
                <br />
                <br />
              </Col>

              <Col md={2}>
                <h5>CXP</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setEdoCtaProveedores(event.currentTarget.checked)
                  }
                  checked={edoCtaProveedores}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Estado Cuenta Proveedores</h5>

                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setPagoProveedores(event.currentTarget.checked)
                  }
                  checked={pagoProveedores}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Pago Proveedores</h5>

                <br />
                <br />

                <h5>REPORTES</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuReportes(event.currentTarget.checked)
                  }
                  checked={menu_reportes}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Reportes</h5>
                <br />
        <br />
      <h5>Cadenas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuCadenas(event.currentTarget.checked)}
        checked={menu_cadenas}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Cadenas</h5>
      
              </Col>

              <Col md={2}>
                <h5>USUARIOS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuUsuarios(event.currentTarget.checked)
                  }
                  checked={menu_usuarios}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Usuarios</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setUsuariosCreate(event.currentTarget.checked)
                  }
                  checked={usuarios_create}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Crear Usuarios</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setUsuariosPermisos(event.currentTarget.checked)
                  }
                  checked={usuarios_permisos}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Permisos Usuarios</h5>
                <br />
                <br />

                <h5>CONFIG</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setConfiguracion(event.currentTarget.checked)
                  }
                  checked={configuracion}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Config</h5>

                <br />
                <br />
                <h5>LOGS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) => setMenuLogs(event.currentTarget.checked)}
                  checked={menu_logs}
                  className="subMenu"
                />
                <h5 className="subMenuTitulo">Menu Logs</h5>
              </Col>
            </Row>

            {/* <ModalFooter> */}
            {/* <div className="container"> */}
            <Row>
              <Col md={11} align="right">
                <Button color="success" onClick={permisosUsuario}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
          {/* </ModalFooter> */}
          {/* </div> */}
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoUsuarios;
