import React, { useState, useMemo, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

function AnalisisArticulosPedidosExpo() {
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `${URL_ARTICULOS_PEDIDO}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allMovs = response.data;
        let arrayMovs = allMovs
        .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
        .map((a) => {
          return {
            articulo: a.articulos[0].codigo + " " + a.articulos[0].nombre,
            pedido: a.pedidos[0].idPedido,
            cliente: a.pedidos[0].clientes[0].nombre_comercial,
            activo: a.is_active,
            autorizadoApartado: a.autorizadoApartado,
            autorizadoSurtido: a.autorizadoSurtido,
            cantidad: a.cantidad,
            surtido: a.surtido,
            pendiente_surtir: a.pendiente_surtir,
          }
        })
        .filter(function (el) {
          return el != null;
        });
      let data = Object.values(arrayMovs);
      setComments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);


  const headers = [
    { name: "Articulo", field: "articulo", sortable: false },
    { name: "Pedido", field: "pedido", sortable: false },
    { name: "Cliente", field: "cliente", sortable: false },
    { name: "Activo", field: "activo", sortable: false },
    { name: "Apartado", field: "autorizadoApartado", sortable: false },
    { name: "Aut Surtir", field: "autorizadoSurtido", sortable: false },
    { name: "Cantidad", field: "cantidad", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
    { name: "Pend Surtir", field: "pendiente_surtir", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.autorizadoApartado.toLowerCase().includes(search.toLowerCase()) ||
          comment.autorizadoSurtido.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.surtido.toString().includes(search) ||
          comment.pendiente_surtir.toString().includes(search) ||
          comment.pedido.toString().includes(search)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);

  function excel() {
    const dataExcel = comments.map((a) => {
        return {
          Articulo: a.articulo,
          Pedido: a.pedido,
          Cliente: a.cliente,
          Activo: a.activo,
          Apartado: a.autorizadoApartado,
          Surtir: a.autorizadoSurtido,
          Cantidad: a.cantidad,
          Surtido: a.surtido,
          Pendiente: a.pendiente_surtir,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    console.log("hola", dataExcelLimpia)
    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Articulo",
          "Pedido",
          "Cliente",
          "Activo",
          "Apartado",
          "Surtir",
          "Cantidad",
          "Surtido",
          "Pendiente",
        ],
        sheetHeader: [
          "Articulo",
          "Pedido",
          "Cliente",
          "Activo",
          "Apartado",
          "Surtir",
          "Cantidad",
          "Surtido",
          "Pendiente",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  let totalCantidad = 0;
  let totalSurtido = 0;
  let totalPendiente = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_ventas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    {/* <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button> */}
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
          </Row>

          <h3 align="center">Analisis Articulos Pedidos Expo</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />

          {/* Tabla Movimientos */}

          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />

            <tbody>
              {commentsData.map((a) => {
                if (a.activo == "Si") {
                  totalCantidad = totalCantidad + parseFloat(a.cantidad);
                  totalSurtido = totalSurtido + parseFloat(a.surtido);
                  totalPendiente =
                    totalPendiente + parseFloat(a.pendiente_surtir);
                  return (
                    <tr>
                      <td>
                        {a.articulo}
                      </td>
                      <td>{a.pedido}</td>
                      <td>{a.cliente}</td>
                      <td>{a.activo}</td>
                      <td>{a.autorizadoApartado}</td>
                      <td>{a.autorizadoSurtido}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.surtido)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.pendiente_surtir
                        )}
                      </td>
                    </tr>
                  );
                }
              })}

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTALES</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCantidad)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalSurtido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPendiente)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default AnalisisArticulosPedidosExpo;
