import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportesVentas() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
          <Row>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasMensualComparativa"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Venta Mensual
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Venta Mensual
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasMensualCliente"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-user-tie fa-7x"></i>
                <br />
                <br />
                Venta Mensual Cliente
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-user-tie fa-7x"></i>
            <br />
            <br />
            Venta Mensual Cliente
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasDepartamento"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-store fa-7x"></i>
                <br />
                <br />
                Ventas Departamento
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-store fa-7x"></i>
            <br />
            <br />
            Ventas Departamento
          </Button> }
            </Col>
            
            
            </Row>
            <br/>
            <Row>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasGrupo"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-users fa-7x"></i>
                <br />
                <br />
                Ventas Grupo
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-users fa-7x"></i>
            <br />
            <br />
            Ventas Grupo
          </Button> }
            </Col>


            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasVendedor"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-address-card fa-7x"></i>
                <br />
                <br />
                Venta Vendedor
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-address-card fa-7x"></i>
            <br />
            <br />
            Venta Vendedor
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasMensualVendedor"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-bar fa-7x"></i>
                <br />
                <br />
                Venta Mensual Vendedor
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-bar fa-7x"></i>
            <br />
            <br />
            Venta Mensual Vendedor
          </Button> }
            </Col>

            </Row>
          <br/>
          <Row>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasEstado"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-globe-americas fa-7x"></i>
                <br />
                <br />
                Venta Estado
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-globe-americas fa-7x"></i>
            <br />
            <br />
            Venta Estado
          </Button> }
            </Col>


            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/ReporteUltimoPrecio"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Ultimo Precio Cliente
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Ultimo Precio Cliente
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/ReporteUltimoPrecioArticulo"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-tshirt fa-7x"></i>
                <br />
                <br />
                Ultimo Precio Articulo
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Ultimo Precio Articulo
          </Button> }
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/ValorInventario"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-money-check-alt fa-7x"></i>
                <br />
                <br />
                Valor Inventario
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-money-check-alt fa-7x"></i>
            <br />
            <br />
            Valor Inventario
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/SellThru"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-search-dollar fa-7x"></i>
                <br />
                <br />
                Sell Thru
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-search-dollar fa-7x"></i>
            <br />
            <br />
            Sell Thru
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasDesgloce"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-bar fa-7x"></i>
                <br />
                <br />
                Desgloce Ventas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-bar fa-7x"></i>
            <br />
            <br />
            Desgloce Ventas
          </Button> }
            </Col>

            </Row>
            <br />
            <Row>

            <Col md={4} align="center">
                    {user.edoCtaClientes ?(
                      <Button href="/ReporteResumenAbonosTiendas" className="botonesMenu" color="success">
                        <i class="fas fa-dollar-sign fa-7x"></i>
                        <br />
                        <br />
                        Resumen Abonos <br /> Tiendas
                      </Button>
                    ): <Button disabled className="botonesMenu" color="success">
                    <i class="fas fa-dollar-sign fa-7x"></i>
                    <br />
                    <br />
                    Resumen Abonos <br /> Tiendas
                  </Button> }
                    </Col>

                    <Col md={4} align="center">
                    {user.edoCtaClientes ?(
                      <Button href="/ReporteResumenVentasTiendas" className="botonesMenu" color="success">
                        <i class="fas fa-money-bill-alt fa-7x"></i>
                        <br />
                        <br />
                        Resumen Ventas <br /> Tiendas
                      </Button>
                    ): <Button disabled className="botonesMenu" color="success">
                    <i class="fas fa-money-bill-alt fa-7x"></i>
                    <br />
                    <br />
                    Resumen Ventas <br /> Tiendas
                  </Button> }
                    </Col>      
                    <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/ReporteVentasLineas"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Reporte Ventas <br /> Lineas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Reporte Ventas <br /> Lineas
          </Button> }
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/ReporteVentasInventario"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Reporte Ventas <br /> Inventario
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Reporte Ventas <br /> Inventario
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/ReporteVentasPorcentaje"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Reporte Ventas <br /> Porcentaje
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Reporte Ventas <br /> Porcentaje
          </Button> }
            </Col>
            </Row>
            <br />
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportesVentas;
