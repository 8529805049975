import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import TablaColaboradores from "./TablaColaboradores";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";

function ListadoColaboradores() {
  const { user } = useContext(AuthContext);
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_PUESTOS = process.env.REACT_APP_URL_PUESTOS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState('');
  const [puestos, setPuestos] = useState([]);
  const [selectedPuesto, setSelectedPuesto] = useState('');
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [activo, setActivo] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');


  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
     
      axios
      .get(URL_PUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPuestos = response.data;
        setPuestos(allPuestos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function PDFTabla() {
    const data = colaboradores.map((a)=>{
      if (
        (selectedPuesto == 0 ||
          selectedPuesto == a.puestos[0]._id) 
        && (selectedColaborador == 0 ||
          selectedColaborador == a.puestos[0].colaboradores[0]._id) 
          && (activo == 0 || activo == a.is_active) &&
          (selectedArea == 0 ||
            selectedArea == a.areas[0]._id)
      ) {
      return [a.is_active, [a.nombre + " " + a.apellido], [a.puestos[0].colaboradores[0].nombre + " " + a.puestos[0].colaboradores[0].apellido], a.telefono, a.puestos[0].name];
    }});
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text(`Listado Empleados`, 20, 25);
    doc.autoTable({
      head: [["Activo", "Nombre", "Jefe", "Telefono", "Puesto"]],
      body: dataPDFLimpia,
      startY: 30,
    });
    doc.save(`Empleados.pdf`);
  }
  function excel(){
    const dataExcel = colaboradores.map((a)=>{
      if (
        (selectedPuesto == 0 ||
          selectedPuesto == a.puestos[0]._id) 
        && (selectedColaborador == 0 ||
          selectedColaborador == a.puestos[0].colaboradores[0]._id) 
          && (activo == 0 || activo == a.is_active) &&
          (selectedArea == 0 ||
            selectedArea == a.areas[0]._id)
      ) {
      return {Activo: a.is_active, Nombre:[a.nombre + " " + a.apellido], Jefe:[a.puestos[0].colaboradores[0].nombre + " " + a.puestos[0].colaboradores[0].apellido], Telefono:a.telefono, Puesto:a.puestos[0].name};
    }});
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = "ListadoEmpleados";
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: "ListadoEmpleados",
    sheetFilter: ["Activo", "Nombre", "Jefe", "Telefono", "Puesto"],
    sheetHeader: ["Activo", "Nombre", "Jefe", "Telefono", "Puesto"],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  function enviaMail(){
    const data = colaboradores.map((a)=>{
      if (
        (selectedPuesto == 0 ||
          selectedPuesto == a.puestos[0]._id) 
        && (selectedColaborador == 0 ||
          selectedColaborador == a.puestos[0].colaboradores[0]._id) 
          && (activo == 0 || activo == a.is_active) &&
          (selectedArea == 0 ||
            selectedArea == a.areas[0]._id)
      ) {
      return [a.is_active, [a.nombre + " " + a.apellido], [a.puestos[0].colaboradores[0].nombre + " " + a.puestos[0].colaboradores[0].apellido], a.telefono, a.puestos[0].name];
    }});
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text(`Listado Empleados`, 20, 25);
    doc.autoTable({
      head: [["Activo", "Nombre", "Jefe", "Telefono", "Puesto"]],
      body: dataPDFLimpia,
      startY: 30,
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Listado Empleados',
         email: mailTo,
         fileName: 'Listado-Empleados.pdf',
         att: base64File,
         text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de empleados.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
setTimeout(() => {
      window.location.reload();
    }, 1000);
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_empleados  ?(
        <div className="row">
          {
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col sm={8}>
                    {user.empleados_create  ?(
                    <Button
                  size="sm"
                    type="submit"
                    className="btn btn-success"
                    href="/EmpleadosCreate"
                  >
                    Nuevo Empleado
                  </Button>
                    ):(
                      <Button
                  size="sm"
                    type="submit"
                    className="btn btn-success"
                    disabled
                  >
                    Nuevo Empleado
                  </Button>
                    )}
                  <Button
                  size="sm"
                    href="/MenuNominas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                    </Col>
                    <Col sm={4}>
                      <ButtonGroup id="logoutBoton">
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTabla}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button size="sm" className="btn" color="info" onClick={toggleMail}>
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excel}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Listado de Empelados</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>

                  <h3 align="center">Empleados</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th style={{ paddingBottom: "0px" }}>Activo</th>
                        <th style={{ paddingBottom: "0px" }}>Nombre</th>
                        <th style={{ paddingBottom: "0px" }}>Jefe</th>
                        <th style={{ paddingBottom: "0px" }}>Telefono</th>
                        <th style={{ paddingBottom: "0px" }}>Puesto</th>
                        <th style={{ paddingBottom: "0px" }}>Area</th>
                        <th style={{ paddingBottom: "0px" }}>Editar</th>
                      </tr>
                      <tr>
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                            bsSize="sm"
                            type="select"
                            value={activo}
                            onChange={(e) => {
                              setActivo(e.target.value);
                            }}
                          >
                            <option value='0'>Selecciona</option>
                            <option value='Si'>Si</option>
                            <option value='No'>No</option>
                          </Input>
                        </td>
                        <td></td>
                       { user.areas == AREAS_GENERAL?
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={selectedColaborador}
                        required
                        onChange={(e) => setSelectedColaborador(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {colaboradores
                          .sort((a, b) => a.nombre > b.nombre ? 1 : -1 )
                          .map((a) => {
                          return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                          })}
                      </Input>
                        </td>:
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={selectedColaborador}
                        required
                        onChange={(e) => setSelectedColaborador(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {colaboradores
                          .sort((a, b) => a.nombre > b.nombre ? 1 : -1 )
                          .map((a) => {
                            if( user.areas == a.areas[0]._id){
                              return <option value={a._id}>{a.nombre} {a.apellido}</option>;}
                          })}
                      </Input>
                        </td>
                        } 
                        <td></td>
                        { user.areas == AREAS_GENERAL? 
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={selectedPuesto}
                        required
                        onChange={(e) => setSelectedPuesto(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {puestos
                          .sort((a, b) => a.name > b.name ? 1 : -1 )
                          .map((a) => {
                          return <option value={a._id}>{a.name} </option>;
                          })}
                      </Input>
                        </td> :
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                      bsSize="sm"
                      type="select"
                      value={selectedPuesto}
                      required
                      onChange={(e) => setSelectedPuesto(e.target.value)}
                    >
                      <option value="0">Selecciona</option>
                      {puestos
                        .sort((a, b) => a.name > b.name ? 1 : -1 )
                        .map((a) => {
                          if( user.areas == a.areas[0]._id){
                        return <option value={a._id}>{a.name} </option>;}
                        })}
                    </Input>
                      </td>  
                      }

                    { user.areas == AREAS_GENERAL? 
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        required
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name > b.name ? 1 : -1 )
                          .map((a) => {
                          return <option value={a._id}>{a.name} </option>;
                          })}
                      </Input>
                        </td> :
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      required
                      onChange={(e) => setSelectedArea(e.target.value)}
                    >
                      <option value="0">Selecciona</option>
                      {areas
                        .sort((a, b) => a.name > b.name ? 1 : -1 )
                        .map((a) => {
                          // if( user.areas == a.areas[0]._id){
                        return <option value={a._id}>{a.name} </option>;
                      // }
                        })}
                    </Input>
                      </td>  
                      }
                        
                      </tr>
                    </thead>
                    <tbody>
                      {
                      user.areas == AREAS_GENERAL ?
                      colaboradores
                        .sort((a, b)=>(a.nombre > b.nombre ? 1 : -1))
                        .map((p) => {
                          if (
                            (selectedPuesto == 0 ||
                              selectedPuesto == p.puestos[0]._id) &&
                              (selectedArea == 0 ||
                                selectedArea == p.areas[0]._id)
                            && (selectedColaborador == 0 ||
                              selectedColaborador == p.puestos[0].colaboradores[0]._id) 
                              && (activo == 0 || activo == p.is_active) &&
                              (selectedArea == 0 ||
                                selectedArea == p.areas[0]._id)
                          ) {
                          return (
                            <tr>
                              <TablaColaboradores
                                is_active={p.is_active}
                                nombre={p.nombre}
                                apellido={p.apellido}
                                email={p.email}
                                jefe={p.puestos[0].colaboradores[0].nombre}
                                jefeApellido={p.puestos[0].colaboradores[0].apellido}
                                telefono={p.telefono}
                                puesto={p.puestos[0].name}
                                idPuesto={p.puestos[0]._id}
                                sueldo={p.sueldo_actual}
                                contEmer={p.contacto_emergencia}
                                telEmer={p.telefono_emergencia}
                                _id={p._id}
                                identificacion={p.identificacion}
                                direccion={p.direccion}
                                fecha_ingreso={p.fecha_ingreso}
                                fecha_nacimiento={p.fecha_nacimiento}
                                contacto_emergencia={p.contacto_emergencia}
                                telefono_emergencia={p.telefono_emergencia}
                                sueldo_actual={p.sueldo_actual}
                                ultimo_aumento={p.ultimo_aumento}
                                sueldo_anterior={p.sueldo_anterior}
                                observaciones={p.observaciones}
                                banco={p.banco}
                                clabe={p.clabe}
                                cobrador={p.cobrador}
                                area={p.areas[0].name}
                                idArea={p.areas[0]._id}
                                acceso_sistema={p.acceso_sistema}
                                frecuencia={p.frecuencia}
                                vendedor={p.vendedor}
                              />
                            </tr>
                          );
                          }
                        }):
                        colaboradores
                        .sort((a, b)=>(a.nombre > b.nombre ? 1 : -1))
                        .map((p) => {
                          if (
                            (selectedPuesto == 0 ||
                              selectedPuesto == p.puestos[0]._id) 
                            && (selectedColaborador == 0 ||
                              selectedColaborador == p.puestos[0].colaboradores[0]._id) 
                              && (activo == 0 || activo == p.is_active) &&
                              user.areas == p.areas[0]._id 
                          ) {
                          return (
                            <tr>
                              <TablaColaboradores
                                is_active={p.is_active}
                                nombre={p.nombre}
                                apellido={p.apellido}
                                email={p.email}
                                jefe={p.puestos[0].colaboradores[0].nombre}
                                jefeApellido={p.puestos[0].colaboradores[0].apellido}
                                telefono={p.telefono}
                                puesto={p.puestos[0].name}
                                idPuesto={p.puestos[0]._id}
                                sueldo={p.sueldo_actual}
                                contEmer={p.contacto_emergencia}
                                telEmer={p.telefono_emergencia}
                                _id={p._id}
                                identificacion={p.identificacion}
                                direccion={p.direccion}
                                fecha_ingreso={p.fecha_ingreso}
                                fecha_nacimiento={p.fecha_nacimiento}
                                contacto_emergencia={p.contacto_emergencia}
                                telefono_emergencia={p.telefono_emergencia}
                                sueldo_actual={p.sueldo_actual}
                                ultimo_aumento={p.ultimo_aumento}
                                sueldo_anterior={p.sueldo_anterior}
                                observaciones={p.observaciones}
                                banco={p.banco}
                                clabe={p.clabe}
                                cobrador={p.cobrador}
                                area={p.areas[0].name}
                                idArea={p.areas[0]._id}
                                acceso_sistema={p.acceso_sistema}
                                frecuencia={p.frecuencia}
                              />
                            </tr>
                          );
                          }
                        })
                        }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
        </div>
      ): undefined } 
      
    </>
  );
}

export default ListadoColaboradores;
