import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import axios from "axios";
import { Table, Col, Input, Label } from "reactstrap";
import Header from '../../layout/Header/Header'


function Monitoring() {
  const { user } = useContext(AuthContext);

  const [statusHeroku, setStatusHeroku] = useState([])
  
  useEffect(()=>{
    axios
    .get(`https://hollander.herokuapp.com/`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allUsers = response.data.message;
      setStatusHeroku(allUsers);
    })
    .catch((err) => {
      console.log(err);
    });
  },[])
  return (
    <>
    <Header />
    <br />
    <br />
      <div className="container">
        <img src={"https://api.netlify.com/api/v1/badges/b09b91b2-e514-4469-b828-6e1bdde0bf7b/deploy-status"}></img>
        <br />
        <br />
        <Label>Heroku: {statusHeroku}</Label>
      </div>
    </>
  );
}

export default Monitoring;
