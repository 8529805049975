import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";

function ReporteVentasLineas() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");

  const [totalCantidadTotal, setTotalCantidadTotal] = useState(0);
  const [totalImporte, setTotalImporte] = useState(0);
  const [totalCajas, setTotalCajas] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(
      `${URL_DASHBOARDS}VentasLineas/${selectedFechaInicio}/${selectedFechaFin}`,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then((response) => {
      let allVentas = response.data;
      // Array para pagination
      let arrayTabla = allVentas
        // .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
        .map((a) => {
          return {
            articulo: a.articulo,
            idArticulo: a.idArticulo,
            piezasPorCaja: a.piezasPorCaja,
            cajas: a.cajas,
            linea: a.linea,
            idLinea: a.idLinea,
            fecha: a.fecha,
            precio: a.precio,
            cantidad: a.cantidad,
            importe: a.importe,
            area: a.area,
            idArea: a.idArea,
          }
        })
        .filter(function (el) {
          return el != null;
        });

        let unicosLineas = arrayTabla.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idLinea === value.idLinea)
        );
        setLineas(unicosLineas);

        
      let data = Object.values(arrayTabla);
      setComments(data);
      //
    })
    .catch((err) => {
      console.log(err);
    });

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      // axios
      // .get(URL_LINEAS, {
      //   headers: {
      //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //   },
      // })
      // .then((res) => {
      //   const allLineas = res.data;
      //   setLineas(allLineas);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  }, [selectedFechaInicio, selectedFechaFin, selectedArea, selectedLinea]);


function PDFTabla() {
  // Objeto para almacenar los datos agrupados por línea
  let dataByLine = {};

  // Iterar sobre los comentarios y agrupar por línea
  comments.forEach((a) => {
      if (!dataByLine[a.linea]) {
          dataByLine[a.linea] = [];
      }
      dataByLine[a.linea].push(a);
  });

  // Función para calcular totales
  const calculateTotals = (groupedData) => {
      let totalCant = 0;
      let totalImp = 0;
      let totalCaj = 0;
      groupedData.forEach((a) => {
          totalCant += a.cantidad;
          totalImp += a.importe;
          totalCaj += a.cajas;
      });
      return [totalCant, totalImp, totalCaj];
  };

  const doc = new jsPDF({ compressPdf: true });
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 150, 5, 33, 10);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 155, 15, 25, 25);

  doc.setFontSize(14);
  doc.text(`Ventas Por Linea`, 15, 30);
  doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 15, 35);

  // Iterar sobre los datos agrupados por línea
  let startY = 45;
  for (const linea in dataByLine) {
      if (dataByLine.hasOwnProperty(linea)) {
          const groupedData = dataByLine[linea];

          // Calcular totales para esta línea
          const [totalCant, totalImp, totalCaj] = calculateTotals(groupedData);

          // Agregar el título de la línea
          doc.setFontSize(12);
          doc.text(`Línea: ${linea}`, 15, startY);

          // Construir tabla para esta línea
          const tableData = groupedData.map((a) => [
              a.articulo,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.importe),
              new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(a.cajas),
          ]);

          // Agregar fila de totales
          tableData.push([
            `Total ${linea}:`,
              new Intl.NumberFormat("en-US").format(totalCant),
              new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalImp),
              new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(totalCaj),
          ]);

          // Generar tabla para esta línea
          doc.autoTable({
              head: [
                  ["Articulo", "Cantidad", "Importe", "Cajas"],
              ],
              body: tableData,
              startY: startY + 10,
          });

          // Incrementar startY para la próxima tabla
          startY = doc.lastAutoTable.finalY + 20;
      }
  }

  window.open(doc.output("bloburl"), "_blank");
}




  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Area: a.area,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ReporteUtilidades";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ReporteUtilidades",
        sheetFilter: [
          "Area",
          "Total",
        ],
        sheetHeader: [
          "Area",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalTabla = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        TotalTabla = TotalTabla + a.total_general;
        return [
          a.area,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);

    doc.setFontSize(14);
    doc.text(`Reporte Utilidades`, 15, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 15, 35);
    doc.autoTable({
      head: [
        [
          "Area",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTabla),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Reporte Utilidades",
          email: mailTo,
          fileName: "ReporteUtilidades.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    // { name: "Linea", field: "linea", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Cajas", field: "cajas", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.linea.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search)||
          comment.importe.toString().includes(search)||
          comment.cajas.toString().includes(search)
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }

    setTotalItems(computedComments.length);

    let can = computedComments.map((c) => parseFloat(c.cantidad));
    let TCan = can.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalCantidadTotal(TCan);

    let car = computedComments.map((c) => parseFloat(c.importe));
    let TCar = car.reduce((t, importe, index) => t + importe, 0);
    setTotalImporte(TCar);

    let abo = computedComments.map((c) => parseFloat(c.cajas));
    let TAbo = abo.reduce((t, cajas, index) => t + cajas, 0);
    setTotalCajas(TAbo);



    //Sorting comments
    if (sorting.field && 
      sorting.field != "cantidad" &&
      sorting.field != "importe" &&
      sorting.field != "cajas"
    ) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computedComments = computedComments.sort(
      (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
  }

  if (
    sorting.field &&
    sorting.order === "asc" &&
    (sorting.field == "cantidad" ||
    sorting.field == "importe" ||
    sorting.field == "cajas")
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  if (
    sorting.field &&
    sorting.order === "desc" &&
    (sorting.field == "cantidad" ||
    sorting.field == "importe" ||
    sorting.field == "cajas")
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedLinea
  ]);

  let totalCant = 0
  let totalImp = 0
  let totalCaj = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button> */}
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ventas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte Ventas por Linea</h3>
          <div className="row">
          <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-12 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
                <Label>Linea</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLinea}
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.linea > b.linea ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idLinea}>{a.linea}</option>;
                    })}
                </Input>
              </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {lineas.map((uni)=>{
                totalCant = 0
                totalImp = 0
                totalCaj = 0
                
              return(
                <>
                <h4>{uni.linea}</h4>

              {commentsData.map((a) => {
                if(uni.idLinea == a.idLinea){
                totalCant = totalCant + a.cantidad
                totalImp = totalImp + a.importe
                totalCaj = totalCaj + a.cajas
                return (
                  <tr>
                    {/* <td>{a.linea}</td> */}
                    <td>{a.articulo}</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                      }).format(a.cantidad)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                      }).format(a.cajas)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.importe)}
                    </td>
                  </tr>
              
                );
                }


              })
              }
              <tr>
              {/* <td></td> */}
              <td className="negrita" align="right">TOTALES {uni.linea}</td>
              <td className="negrita">
                  {new Intl.NumberFormat("en-US", {maximumFractionDigits: 0}).format(totalCant)}
              </td>
              <td className="negrita">
                  {new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(totalCaj)}
              </td>
              <td>
                {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImp)}
              </td>
            </tr>
                <br />
            </>
              )
              
            })
          }
              {/* <tr>
                <td></td>
                <td className="negrita" align="right">TOTALES</td>
                <td className="negrita">
                    {new Intl.NumberFormat("en-US", {maximumFractionDigits: 0}).format(totalCant)}
                </td>
                <td>
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImp)}
                </td>
                <td className="negrita">
                    {new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(totalCaj)}
                </td>
              </tr> */}


              <tr style={{ backgroundColor: "#00bfff" }}>
              {/* <td></td> */}
                <td className="negrita" align="right">
                  TOTAL GENERAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(totalCantidadTotal)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(totalCajas)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImporte)}
                </td>
              </tr>

            </tbody>
          </Table>
          <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ReporteVentasLineas;
