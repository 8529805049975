import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function VentaDirectaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_MIXCALCO = process.env.REACT_APP_AREAS_MIXCALCO;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  // const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("650a334ab99f0b3555a8cf16");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [cliente, setCliente] = useState("");
  const [condPago, setCondPago] = useState("Contado");
  const [departamentoCliente, setDepartamentoCliente] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [paqueteria, setPaqueteria] = useState("Tienda");
  const [idPaqueteria, setIdPaqueteria] = useState("650b273bc9855e3f883e8f5d");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(AREAS_CEDIS);
  const [selectedAreaName, setSelectedAreaName] = useState("CEDIS");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_cajas, setTotalCajas] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const [listaPrecios, setListaPrecios] = useState(1);

  const [bancos, setBancos] = useState([]);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [pago, setPago] = useState();
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [referencia, setReferencia] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("Tarjeta");
  const [saldo, setSaldo] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      articulosNombre: "",
      observacionesArticulo: "NA",
      idInv: "",
      costoUnitario:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  useMemo(() => {
    // axios
    //   .get(`${URL_ARTICULOS}Activos`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allArticulos = res.data;
    //     setArticulos(allArticulos);
    //     let result = allArticulos.map((a) => a._id);
    //     setIdsArticulos(result);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(`${URL_CLIENTES}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      if(selectedArea){
    axios
      .get(`${URL_INVENTARIOS}DisponibleAlmacen/${selectedArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setInventarios(allDisponible);

        setArticulos(allDisponible);
        // let result = allDisponible.map((a) => a.idArticulo);
        // setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

      if(selectedCliente == "650a334ab99f0b3555a8cf16"){
        axios
        .get(`${URL_CLIENTES}/${selectedCliente}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allCliente = res.data;
          setCliente(allCliente);
          setDescuento(allCliente.descuento);
          setListaPrecios(allCliente.listaPrecios);
          setSelectedColaborador(allCliente.vendedor[0]._id);
          setSelectedColaboradorName(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
          setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
          setCondPago(allCliente.forma_pago);
          if(allCliente.forma_pago == "Credito"){
            setPago(false)
          }else setPago(true)
            })
            .catch((err) => {
              console.log(err);
            });
      }
  }, [selectedArea]);


  const savePedido = async (event) => {
    event.preventDefault();
    let cantidadIF = inputFields.length;
    let contador = 0;
    if (cambiarPrecio == false && user.precio_menor == false) {
      inputFields.map(async (a) => {
        if (a.precioOriginal - 51 < a.precio) {
          return (contador = contador + 1);
        }
      });
    } else {
      contador = inputFields.length;
    }
    if((condPago == "Contado" && saldo == 0) ||
    (condPago == "Credito")){
      if (!pago) {
        if (contador == cantidadIF) {
          if (selectedCliente != "" && selectedArea != "") {
            setValidaBoton(false);
            toggleProgreso();
            let totalPedido = inputFields.length;
            try {
              await axios
                .post(
                  URL_PEDIDOS,
                  {
                    fecha,
                    clientes: selectedCliente,
                    departamentosClientes: departamentoCliente,
                    colaboradores: selectedColaborador,
                    total_general: parseFloat(total_general).toFixed(2),
                    observaciones,
                    total_cantidad,
                    paqueterias: idPaqueteria,
                    areas: selectedArea,
                    comisionTDC,
                    comisionFlete,
                    subTotal: parseFloat(subTotal).toFixed(2),
                    iva: parseFloat(iva).toFixed(2),
                    impuestos: selectedImpuesto,
                    surtido: "Si",
                    autorizadoApartado: "Si",
                    autorizadoSurtido: "Si",
                    editado: "No",
                    fechaModificado: fecha,
                    creado: user.id,
                    fecha_estimada: fecha,
                    fecha_entrega: fecha,
                    entregaInsumos: "No",
                    descuento,
                    anticipo: "No",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(async (dataPedido) => {
                  await axios
                    .post(
                      URL_SURTIDOS,
                      {
                        fecha,
                        pedidos: dataPedido.data._id,
                        observaciones,
                        cond_pago: "Credito",
                        total_cantidad,
                        user: user.id,
                        clientes: selectedCliente,
                        areas: selectedArea,
                        total_general: parseFloat(total_general).toFixed(2),
                        saldo: parseFloat(saldo).toFixed(2),
                        subTotal: parseFloat(subTotal).toFixed(2),
                        iva: parseFloat(iva).toFixed(2),
                        impuestos: selectedImpuesto,
                        is_active: "Si",
                        descuento,
                        colaboradores: selectedColaborador,
                        comisionPagada: "No",
                        comisionFlete,
                        comisionTDC,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((dataSurtido) => {
  
                      PDFOCSurtido(
                        dataSurtido.data.idSurtido,
                        dataSurtido.data.updatedAt,
                        dataPedido.data.idPedido
                      );
  
                      inputFields.map((a) => {
                        if (a.articulos != "" && a.cantidad != 0) {
                          axios
                            .post(
                              URL_ARTICULOS_PEDIDO,
                              {
                                pedidos: dataPedido.data._id,
                                articulos: a.articulos,
                                clientes: selectedCliente,
                                cantidad: parseFloat(a.cantidad),
                                pendiente_surtir: parseFloat(a.cantidad),
                                surtido: 0,
                                precio: parseFloat(a.precio).toFixed(2),
                                total: parseFloat(a.total).toFixed(2),
                                pack: "NA",
                                autorizadoSurtido: "Si",
                                autorizadoApartado: "Si",
                                observaciones: a.observacionesArticulo,
                                fecha
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then((dataArtPed) => {
                              axios
                                .post(
                                  URL_ARTICULOS_SURTIDOS,
                                  {
                                    fecha,
                                    surtidos: dataSurtido.data._id,
                                    inventarios: a.idInv,
                                    cantidad: parseFloat(a.cantidad),
                                    articulos: a.articulos,
                                    pedidos: dataPedido.data._id,
                                    articulosPedido: dataArtPed.data._id,
                                    costoUnitario: a.costoUnitario,
                                    clientes: selectedCliente,
                                    precio: parseFloat(a.precio).toFixed(2),
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer: ${localStorage.getItem(
                                        "app_token"
                                      )}`,
                                    },
                                  }
                                )
  
                                .then((data) => {
                                  totalPedido = totalPedido - 1;
                                  if (totalPedido == 0) {
                                    axios
                                      .post(
                                        URL_LOGS,
                                        {
                                          tipo: "Crear Pedido",
                                          detalle: `${value._id} ${total_general}`,
                                          user: user.id,
                                        },
                                        {
                                          headers: {
                                            Authorization: `Bearer: ${localStorage.getItem(
                                              "app_token"
                                            )}`,
                                          },
                                        }
                                      )
                                      .then(() => {
                                        Swal.fire(
                                          "Good job!",
                                          "Creado con exito",
                                          `success`
                                        );
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000);
                                      });
                                  }
                                })
                                .catch((error) => {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    // footer: `${error.response.data}`,
                                  });
                                  console.log(error);
                                  setValidaBoton(true);
                                });
                            });
                        } else {
                          totalPedido = totalPedido - 1;
                          if (totalPedido == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Pedido",
                                  detalle: `${value._id} ${total_general}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  `success`
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              });
                          }
                        }
                      });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        // footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Faltan datos, favor de revisar",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No puedes vender $50 menos del precio original",
            showConfirmButton: false,
          });
        }
      } else {
        let t = parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer)
        if (contador == cantidadIF) {
          if (selectedCliente != "" && selectedArea != "" && t > 0) {
            setValidaBoton(false);
            toggleProgreso();
            let totalPedido = inputFields.length;
            try {
              await axios
                .post(
                  URL_PEDIDOS,
                  {
                    fecha,
                    clientes: selectedCliente,
                    departamentosClientes: departamentoCliente,
                    colaboradores: selectedColaborador,
                    total_general: parseFloat(total_general).toFixed(2),
                    observaciones,
                    total_cantidad,
                    paqueterias: idPaqueteria,
                    areas: selectedArea,
                    comisionTDC,
                    comisionFlete,
                    subTotal: parseFloat(subTotal).toFixed(2),
                    iva: parseFloat(iva).toFixed(2),
                    impuestos: selectedImpuesto,
                    surtido: "Si",
                    autorizadoApartado: "Si",
                    autorizadoSurtido: "Si",
                    editado: "No",
                    fechaModificado: fecha,
                    creado: user.id,
                    fecha_estimada: fecha,
                    fecha_entrega: fecha,
                    entregaInsumos: "No",
                    descuento,
                    anticipo: "No",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(async (dataPedido) => {
                  await axios
                    .post(
                      URL_SURTIDOS,
                      {
                        fecha,
                        pedidos: dataPedido.data._id,
                        observaciones,
                        cond_pago: "Contado",
                        total_cantidad,
                        user: user.id,
                        clientes: selectedCliente,
                        areas: selectedArea,
                        total_general: parseFloat(total_general).toFixed(2),
                        saldo: parseFloat(saldo).toFixed(2),
                        subTotal: parseFloat(subTotal).toFixed(2),
                        iva: parseFloat(iva).toFixed(2),
                        impuestos: selectedImpuesto,
                        is_active: "Si",
                        descuento,
                        colaboradores: selectedColaborador,
                        comisionPagada: "No",
                        comisionFlete,
                        comisionTDC,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(async (dataSurtido) => {
  
                      PDFOCSurtido(
                        dataSurtido.data.idSurtido,
                        dataSurtido.data.updatedAt,
                        dataPedido.data.idPedido
                      );
                      
                      await axios
                      .post(
                        URL_ABONOS,
                        {
                          fecha,
                          total: t,
                          areas: selectedArea,
                          clientes: selectedCliente,
                          surtidos: dataSurtido.data._id,
                          efectivo: efectivo,
                          tdc: tdc,
                          transfer: transfer,
                          fechaTransfer: fechaTransfer,
                          referencia: referencia,
                          cortesia: 0,
                          nomina:0,
                          destino: "Transfer",
                          terminal: "Tarjeta",
                          codigoAgrupado,
                          comisionTDC,
                          concepto:"Abono",
                          observaciones: "NA",
                          condPago: "Contado",
                          anticipo: "No",
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                          },
                        }
                      )
  
                      inputFields.map((a) => {
                        if (a.articulos != "" && a.cantidad != 0) {
                          axios
                            .post(
                              URL_ARTICULOS_PEDIDO,
                              {
                                pedidos: dataPedido.data._id,
                                articulos: a.articulos,
                                clientes: selectedCliente,
                                cantidad: parseFloat(a.cantidad),
                                pendiente_surtir: 0,
                                surtido: parseFloat(a.cantidad),
                                precio: parseFloat(a.precio).toFixed(2),
                                total: parseFloat(a.total).toFixed(2),
                                pack: "NA",
                                autorizadoSurtido: "Si",
                                autorizadoApartado: "Si",
                                observaciones: a.observacionesArticulo,
                                fecha
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then((dataArtPed) => {
                              axios
                                .post(
                                  URL_ARTICULOS_SURTIDOS,
                                  {
                                    fecha,
                                    surtidos: dataSurtido.data._id,
                                    inventarios: a.idInv,
                                    cantidad: parseFloat(a.cantidad),
                                    articulos: a.articulos,
                                    pedidos: dataPedido.data._id,
                                    articulosPedido: dataArtPed.data._id,
                                    costoUnitario: a.costoUnitario,
                                    clientes: selectedCliente,
                                    precio: parseFloat(a.precio).toFixed(2),
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer: ${localStorage.getItem(
                                        "app_token"
                                      )}`,
                                    },
                                  }
                                )
  
                                .then((data) => {
                                  totalPedido = totalPedido - 1;
                                  if (totalPedido == 0) {
                                    axios
                                      .post(
                                        URL_LOGS,
                                        {
                                          tipo: "Crear Pedido",
                                          detalle: `${value._id} ${total_general}`,
                                          user: user.id,
                                        },
                                        {
                                          headers: {
                                            Authorization: `Bearer: ${localStorage.getItem(
                                              "app_token"
                                            )}`,
                                          },
                                        }
                                      )
                                      .then(() => {
                                        Swal.fire(
                                          "Good job!",
                                          "Creado con exito",
                                          `success`
                                        );
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000);
                                      });
                                  }
                                })
                                .catch((error) => {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    // footer: `${error.response.data}`,
                                  });
                                  console.log(error);
                                  setValidaBoton(true);
                                });
                            });
                        } else {
                          totalPedido = totalPedido - 1;
                          if (totalPedido == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Pedido",
                                  detalle: `${value._id} ${total_general}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  `success`
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              });
                          }
                        }
                      });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        // footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Faltan datos, favor de revisar",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No puedes vender $50 menos del precio original",
            showConfirmButton: false,
          });
        }
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes de pagar el total de la venta",
        // footer: `${error.response.data}`,
      });
    }

  };

  async function PDFOCSurtido(
    numero, 
    fechaHora,
    pedido
    ) {

    let fechaHora2 = moment(fechaHora).utcOffset('-0600').format("DD-MM-YYYY") + " " + moment(fechaHora).utcOffset('-0600').format("HH:mm:ss")

    const dataIF = inputFields.map((a) => {
      if(a.cantidad > 0){
            return {
              articulos: a.articulos,
              articuloNombre: a.articulosNombre,
              cantidad: a.cantidad,
              idArticulo: a.idArtPed,
              precio: a.precio,
              cajas: a.cantidad / a.piezasPorCaja,
              piezasPorCaja: a.piezasPorCaja
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(dataIF);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        const data = dataFinal.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articuloNombre,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            a.piezasPorCaja,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 21, 30, 30);

        var img3 = new Image();
        img3.src = process.env.PUBLIC_URL + "logoWA.png";
        doc.addImage(img3, "png", 140, 40, 5, 5);
        var img4 = new Image();
        img4.src = process.env.PUBLIC_URL + "logoFB.png";
        doc.addImage(img4, "png", 140, 47, 5, 5);
        var img5 = new Image();
        img5.src = process.env.PUBLIC_URL + "logoIG.png";
        doc.addImage(img5, "png", 140, 54, 5, 5);

        let alto = 5

        doc.setFontSize(14);
        doc.text(`HOLLANDER BH`,50, alto + 15);
        doc.setFontSize(10);
        doc.text(`RFC HBH190522NDA`, 50, alto + 20);
        doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 50, alto + 25);
        doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 50, alto + 30);
        doc.text(`CMX, CP: 06020`, 50, alto + 35);
        doc.text(`Tel: 55-5542-5297`, 50, alto + 40);
        doc.text(`www.hollandermexico.com`, 50, alto + 45);

        doc.setFontSize(10);
        doc.text(`Fecha:`, 140, alto + 15);
        doc.text(`${fecha}`, 160, alto + 15);
        doc.text(`Nota:`, 140, alto + 20);
        doc.text(`${numero}`, 160, alto + 20);
        doc.text(`Paqueteria:`, 140, alto + 25);
        doc.text(`${paqueteria}`, 160, alto + 25);
        doc.text(`Ejecutivo:`, 140, alto + 30);
        doc.text(`${selectedColaboradorName}`, 160, alto + 30);

        doc.text(`5614826826`, 147, alto + 39);
        doc.text(`@hollandermexico`, 147, alto + 45);
        doc.text(`@hollandermexico`, 147, alto + 52);

        doc.text(`Facturado a: ${cliente.nombre_comercial}`, 10, alto + 55);

        // doc.setFontSize(10);
        // doc.text(`Fecha:`, 35, 15);
        // doc.text(`${fecha}`, 60, 15);
        // doc.text(`Nota:`, 35, 20);
        // doc.text(`${numero}`, 60, 20);
        // doc.text(`Pedido:`, 35, 25);
        // doc.text(`${pedido}`, 60, 25);

        // doc.text(`Ejecutivo:`, 120, 15);
        // doc.text(`${selectedColaboradorName}`, 150, 15);
        // doc.text(`Almacen:`, 120, 20);
        // doc.text(`${selectedAreaName}`, 150, 20);

        // doc.setFontSize(8);
        // doc.text(`Creado por: ${user.nombre} ${user.apellido}`, 120, 32);
        // doc.text(`Fecha creado: ${fechaHora2}`, 120, 37);

        // doc.text(`Cliente: ${cliente.codigo} - ${cliente.nombre_comercial}`, 10, 32);
        // doc.text(`RFC: ${cliente.RFC}`, 10, 37);
        // doc.text(`Direccion de Envio: ${cliente.calle} #${cliente.numero_ext} Int. ${cliente.numero_int}`, 10, 47);
        // doc.text(`Telefono: ${cliente.telefono}`, 10, 52);
        // doc.text(`Edo: ${cliente.estado}, Ciudad: ${cliente.ciudad}, Del: ${cliente.delegacion}, Col: ${cliente.colonia}, CP: ${cliente.cp}`, 10, 57);

        // doc.setFontSize(10);
        // doc.text(`Paqueteria: ${paqueteria}`, 120, 50);

        doc.autoTable({
          head: [["Articulo", "Cajas", "PPC", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: [3, 165, 252],
            lineWidth: 0
          },
          margin: { left: 5, right: 5 },
          startY: 70,
          theme:"plain",
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              "",
              new Intl.NumberFormat("en-US").format(total_cantidad) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(subTotal.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionTDC.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionFlete.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        var lines2 = doc.splitTextToSize(`*** Pendiente por Facturar ***`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 15, lines2);

        // doc.save(`Nota-${numero}.pdf`);
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");

        // var att = doc.output("arraybuffer");
        // var base64File = encode(att);

        // axios
        // .post(
        //   `${URL_WHATSAPP}SendPDF`,
        //   {
        //     number: cliente.telefono,
        //     message:`Hola ${cliente.nombre_comercial}, Aquí te enviamos la nota de tu compra`,
        //     base64File,
        //     fileName:`Nota-${numero}.pdf`
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer: ${localStorage.getItem(
        //         "app_token"
        //       )}`,
        //     },
        //   }
        // )
        //   }
        // });
        // });
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        observacionesArticulo: "NA",
        idInv: "",
        costoUnitario:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let totalCajas = values.map((c) => parseFloat(c.cajas));
    let TCajas = totalCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TCajas);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral((totTemp).toFixed(2));
  };

  const handleChangeInputObservaciones = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.observacionesArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = (
          parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)
        ).toFixed(2);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    if (
      inputFields.filter((e) => e.articulos == selectedArticulo.idArticulo).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulosNombre = "";
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      // articulos.map((a) => {
        // if (a._id == selectedArticulo._id) {
          let idArticulo = selectedArticulo.idArticulo;
          let piezasPorCaja = selectedArticulo.piezasPorCaja;
          let articulosNombre = selectedArticulo.codigo + " " + selectedArticulo.nombre;
          let idInv = selectedArticulo.idInv;
          let costoUnitario = selectedArticulo.costoUnitario
          let precio;
          let precioOriginal;
          if (listaPrecios == 1) {
            precio = selectedArticulo.lista1;
            precioOriginal = selectedArticulo.lista1;
          } else if (listaPrecios == 2) {
            precio = selectedArticulo.lista2;
            precioOriginal = selectedArticulo.lista2;
          } else if (listaPrecios == 3) {
            precio = selectedArticulo.lista3;
            precioOriginal = selectedArticulo.lista3;
          } else if (listaPrecios == 4) {
            precio = selectedArticulo.lista4;
            precioOriginal = selectedArticulo.lista4;
          }
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            precioOriginal,
            articulosNombre,
            idInv,
            costoUnitario
          );
        // }
      // });
    }
  }

  // function BuscaCodigo(id, event) {
  //   if (event.target.value.length >= 24) {
  //     if (
  //       inputFields.filter((e) => e.articulos == event.target.value).length > 0
  //     ) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "El modelo ya fue capturado!",
  //         showConfirmButton: false,
  //       });
  //       const newInputFields = inputFields.map((i) => {
  //         if (id === i.id) {
  //           i.articulosNombre = "";
  //           i.articulos = "";
  //         }
  //         return i;
  //       });
  //       setInputFields(newInputFields);
  //     } else {
  //       if (idsArticulos.includes(event.target.value)) {
  //         articulos.map((a) => {
  //           if (a._id == event.target.value) {
  //             let idArticulo = a.idArticulo;
  //             let piezasPorCaja = a.piezasPorCaja;
  //             let precio = a.venta;
  //             let precioOriginal = a.venta;
  //             let articulosNombre = a.codigo;
  //             handleChangeInputArticulo(
  //               id,
  //               idArticulo,
  //               piezasPorCaja,
  //               precio,
  //               precioOriginal,
  //               articulosNombre
  //             );
  //           }
  //         });
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "El modelo no aparece en tu catalogo",
  //           showConfirmButton: false,
  //         });
  //         const newInputFields = inputFields.map((i) => {
  //           if (id === i.id) {
  //             i.articulosNombre = "";
  //             i.articulos = "";
  //           }
  //           return i;
  //         });
  //         setInputFields(newInputFields);
  //       }
  //     }
  //   } else {
  //     const newInputFields = inputFields.map((i) => {
  //       if (id === i.id) {
  //         i.articulos = event.target.value;
  //       }
  //       return i;
  //     });
  //     setInputFields(newInputFields);
  //   }
  // }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre,
    idInv,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.precioOriginal = precioOriginal;
        i.idInv = idInv;
        i.costoUnitario = costoUnitario
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let totalCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TCajas = totalCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TCajas);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

      setTotalGeneral((totTemp).toFixed(2));

    setSaldo((totTemp - efectivo - tdc - transfer).toFixed(2));
  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp = temp + parseFloat(comisionTDC) + parseFloat(comisionFlete);

    setTotalGeneral((totTemp).toFixed(2));
    setSaldo((totTemp - efectivo - tdc - transfer).toFixed(2))
  }

  const options = articulos.map((option) => {
    const junta = option.codigo + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {
    // setInputFields([
    //   {
    //     id: uuidv4(),
    //     articulos: "",
    //     piezasPorCaja: 0,
    //     cajas: 0,
    //     cantidad: 0,
    //     precio: 0,
    //     precioOriginal: 0,
    //     total: 0,
    //     articulosNombre: "",
    //     observacionesArticulo: "NA",
    //     idInv: "",
    //     costoUnitario:0
    //   },
    // ]);

    setSelectedCliente(cliente);

    axios
      .get(`${URL_CLIENTES}/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        setDescuento(allCliente.descuento);
        setListaPrecios(allCliente.listaPrecios);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorName(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
        setCondPago(allCliente.forma_pago);
        if(allCliente.forma_pago == "Credito"){
          setPago(false)
        }else setPago(true)
          })
          .catch((err) => {
            console.log(err);
          });
      }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
      
    } else {
      setIva(0);
    }
   

    setTotalGeneral(
      ((e * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)).toFixed(2)
    );

    setSaldo(
     ( (e * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) 
    - efectivo - tdc - transfer).toFixed(2)
    )

  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }

    let temp = (selectedImpuesto * subTotal) / 100 +
    subTotal +
    parseFloat(e) +
    parseFloat(comisionFlete) -
    parseFloat((descuentoNumero * subTotal) / 100)
    
    setTotalGeneral((temp).toFixed(2));

    setSaldo((temp - efectivo - tdc - transfer).toFixed(2))
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }

        let temp = (selectedImpuesto * subTotal) / 100 +
    subTotal +
    parseFloat(e) +
    parseFloat(comisionTDC) -
    parseFloat((descuentoNumero * subTotal) / 100)

    setTotalGeneral((temp).toFixed(2));

    setSaldo((temp - efectivo - tdc - transfer).toFixed(2))
  }

  function autorizaCambio() {
    if (password == randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: "dany@sistemify.com",
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${user.nombre} ${user.apellido} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaEfectivo(e) {
    setEfectivo(e);
    setSaldo(
      (total_general - e - tdc - transfer).toFixed(2)
      // - cortesia
    );
  }

  function jalaTransfer(e) {
    setTransfer(e);
    if (e > 0) {
      setDestino("");
    } else {
      setDestino("NA");
    }
    setSaldo((total_general - efectivo - tdc - e).toFixed(2));
  }

  function jalaTDC(e) {
    setTDC(e);
    if (e > 0) {
      setTerminal("");
    } else {
      setTerminal("NA");
    }
    setSaldo((total_general - efectivo - e - transfer).toFixed(2));
  }

  function jalaArea(e){
    setSelectedArea(e);
    areas.map((a)=>{
      if(a._id == e){
        setSelectedAreaName(a.name)
      }
    })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Venta Directa</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  jalaCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                // onChange={(e) => {
                //   setSelectedColaborador(e.target.value)
                // }}
                disabled
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={idPaqueteria}
                required
                onChange={(e) => {
                  setIdPaqueteria(e.target.value);
                }}
              >
                <option value="0">Selecciona </option>
                {paqueterias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={1}>
                  <Label>Cond de Pago</Label>
                  <Input
                    type="select"
                    value={condPago}
                    required
                    disabled={selectedCliente == "650a334ab99f0b3555a8cf16" ? true : false}
                    onChange={(e) => {
                      setCondPago(e.target.value);
        if(e.target.value == "Credito"){
          setPago(false)
        }else setPago(true)
                    }}
                  >
                    <option value={""}>Selecciona</option>
                    <option value={"Contado"}>Contado</option>
                    <option value={"Credito"}>Credito</option>
                  </Input>
                </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  jalaArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a._id != AREAS_MIXCALCO) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            <Col md={1}>
              <Label>Comision TDC</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col>
            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Cambio de Precio</Label>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={(e) => toggleCambiaPrecio()}
              >
                Solicitar
              </Button>
            </Col>
          </Row>
          <br />

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Table borderless>
            <thead>
              <tr>
                {/* <th style={{ maxWidth: "120px" }}>Codigo Qr</th> */}
                <th>Buscador</th>
                <th>Articulo</th>
                <th style={{ maxWidth: "60px" }}>Disponible</th>
                {/* <th>Observaciones</th> */}
                <th style={{ maxWidth: "60px" }}>Cajas</th>
                <th style={{ maxWidth: "60px" }}>Pzs x Caja</th>
                <th style={{ maxWidth: "60px" }}>Cantidad</th>
                <th style={{ maxWidth: "60px" }}>Precio Original</th>
                <th style={{ maxWidth: "60px" }}>Precio</th>
                <th style={{ maxWidth: "80px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField) => (
                <>
                <tr>
                  {/* <td style={{ maxWidth: "120px" }}>
                    <Input
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </td> */}
                  <td>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticulo(inputField.id, selectedArticulo);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </td>
                  <td>
                    <Input
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input type="select" disabled>
                      {inventarios.map((a) => {
                        if (inputField.articulos == a.idArticulo) {
                          return <option>{a.disponible}</option>;
                        }
                      })}
                    </Input>
                  </td>
                  {/* <td>
                <Input
                    name="observaciones"
                    type="text"
                    placeholder="observaciones"
                    value={inputField.observacionesArticulo}
                    required
                    onChange={(event) =>
                      handleChangeInputObservaciones(inputField.id, event)
                    }
                  />
                </td> */}
                   <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                    ):(
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      disabled
                    ></Input>
                    )}
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                    ):(
                      <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      disabled
                    />
                    )}
                    
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      // bsSize="sm"
                      name="precioOriginal"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="PrecioOriginal"
                      value={inputField.precioOriginal}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      style={
                        inputField.precioOriginal - 50 > inputField.precio
                          ? { backgroundColor: "#ed8c8c" }
                          : null
                      }
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </td>
                  <td style={{ maxWidth: "80px" }}>
                    <Input
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="total"
                      value={inputField.total}
                      disabled
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </td>
                </tr>
                
                </>
              ))}
            </tbody>
            {/* <tr>
              <td></td>
              <td></td>
              <td style={{ maxWidth: "60px" }}></td>
                  <td style={{ maxWidth: "60px" }}>
                    <Label>Total Cajas</Label>
                  <Input
                type="number"
                min="0"
                step="any"
                placeholder="Cajas"
                value={total_cajas}
                disabled
              />
                  </td>
                </tr> */}
          </Table>
          <br />
          <Row>
            <Col md={5}></Col>
            <Col md={1}>
            <Label>Total Cajas</Label>
                  <Input
                type="number"
                min="0"
                step="any"
                placeholder="Cajas"
                value={total_cajas}
                disabled
              /></Col>
          </Row>
          <Row>
            {/* </Row> */}
            <br />
            {condPago == "Contado" ? (
            <Col md={3}>
            <Input
              type="checkbox"
              //  onChange={(event) => setPago(event.currentTarget.checked)}
              checked={pago}
              disabled
            />

            <h4 className="subMenuTitulo">Deseas Pagar la Nota?</h4>
            </Col>
             ) : (
               <Col md={3}>
               <Input
                 type="checkbox"
                 onChange={(event) => setPago(event.currentTarget.checked)}
                 checked={pago}
               />

               <h4 className="subMenuTitulo">Deseas Pagar la Nota?</h4>
               </Col>
             )}
           
            {pago ? (
              <>
                {/* <Row> */}
                  <Col md={1}>
                    <Label>Efectivo</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={efectivo}
                      required
                      onChange={(e) => {
                        jalaEfectivo(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={tdc}
                      required
                      onChange={(e) => {
                        jalaTDC(e.target.value);
                      }}
                    />
                  </Col>



                  <Col md={1}>
                    <Label>Transfer</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={transfer}
                      required
                      onChange={(e) => {
                        jalaTransfer(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Referencia</Label>
                        {transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="text"
                            name="referencia"
                            value={referencia}
                            onChange={(e) => {
                              setReferencia(e.target.value);
                            }}
                            />
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="referencia"
                              value={referencia}
                              disabled
                            />
                          )}
                        </Col>


                  <Col md={1}>
                    <Label>Saldo</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={saldo}
                      required
                      disabled
                    />
                  </Col>
                {/* </Row> */}
              </>
            ) : undefined}
          </Row>
          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoPedidos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default VentaDirectaCreate;
