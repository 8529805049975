import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { encode } from "base64-arraybuffer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_BOLIVAR = process.env.REACT_APP_AREAS_BOLIVAR;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [mailTo, setMailTo] = useState("danysalame18@gmail.com");

  const [embarques, setEmbarques] = useState([]);
  const [selectedEmbarque, setSelectedEmbarque] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_general, setTotalGeneral] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [articulosEmb, setArticulosEmb] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idArticulosEmabrque: "",
      articulos: "",
      articulosName: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      precio: 0,
      precioMX: 0,
      tipoCambio: 0,
      total:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_EMBARQUES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allEmbarques = res.data;
        setEmbarques(allEmbarques);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    event.preventDefault();
    if (fecha != "" && observaciones != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalArticulos =
        inputFields.length + inputFields.length + inputFields.length;
      try {
        await axios
          .post(
            URL_COMPRAS,
            {
              fecha,
              embarques: selectedEmbarque,
              observaciones,
              total_cantidad,
              user: user.id,
              areas: selectedArea,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.articulos != "" && a.cantidad != 0) {
                axios
                  .patch(
                    `${URL_INVENTARIOS}Compra`,
                    {
                      articulos: a.articulos,
                      pack: "NA",
                      cantidad: parseFloat(a.cantidad),
                      areas: selectedArea,
                      tamanoCaja: "0",
                      pesoCaja: 1,
                      embarques: selectedEmbarque,
                      precioMX: a.precio * tipoCambio,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalArticulos = totalArticulos - 1;
                    if (totalArticulos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `EMBARQUES ${selectedEmbarque}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              } else {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `EMBARQUES ${selectedEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            // Termina primer map
            inputFields.map((w) => {
              axios
                .patch(
                  `${URL_ARTICULOS_EMBARQUES}EditEmbarcado/${w.idArticulosEmabrque}`,
                  {
                    cantidad: w.cantidad,
                    pesoCaja: 1,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `EMBARQUES ${selectedEmbarque}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });
            // Termina segundo map
            inputFields.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    fecha,
                    cantidad: parseFloat(w.cantidad),
                    embarques: selectedEmbarque,
                    compras: data.data._id,
                    articulosEmbarques: w.idArticulosEmabrque,
                    articulos: w.articulos,
                    pack: "NA",
                    areas: selectedArea,
                    precio: w.precio,
                    tipoCambio: tipoCambio,
                    precioMX: w.precio * tipoCambio,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `EMBARQUES ${selectedEmbarque}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        // Mail
                        // let total_importe = 0;
                        // let total_cajas = 0;
                        // let totalCantidad = 0;
                        // const dataIF = inputFields.map((a) => {
                        //   total_importe = total_importe + a.precio * a.cantidad;
                        //   total_cajas = total_cajas + a.cajas;
                        //   totalCantidad = totalCantidad + a.cantidad;
                        //   return [
                        //     a.articulosName,
                        //     a.cajas,
                        //     a.piezasPorCaja,
                        //     new Intl.NumberFormat("en-US").format(a.cantidad),
                        //     "$" +
                        //       new Intl.NumberFormat("en-US").format(a.precio),
                        //     "$" +
                        //       new Intl.NumberFormat("en-US").format(
                        //         a.precio * a.cantidad
                        //       ),
                        //   ];
                        // });

                        // const dataPDF = Object.values(dataIF);
                        // const dataPDFLimpia = dataPDF.filter(function (el) {
                        //   return el != null;
                        // });
                        // const doc = new jsPDF("landscape", "mm", "a4", true);
                        // var img = new Image();
                        // img.src =
                        //   process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                        // doc.addImage(img, "png", 230, 30, 45, 15);
                        // var img2 = new Image();
                        // img2.src =
                        //   process.env.PUBLIC_URL + "logoInstitucion.png";
                        // doc.addImage(img2, "png", 140, 15, 25, 25);
                        // doc.text(`Compra # ${data.data.idCompras}`, 20, 20);
                        // doc.setFontSize(12);
                        // doc.text(`Fecha ${data.data.fecha}`, 20, 25);
                        // // doc.text(`Contenedor ${contenedor}`, 20, 30);
                        // doc.text(
                        //   `Piezas ${new Intl.NumberFormat("en-US").format(
                        //     data.data.total_cantidad
                        //   )}`,
                        //   20,
                        //   35
                        // );
                        // doc.autoTable({
                        //   head: [
                        //     [
                        //       "Articulo",
                        //       "Cajas",
                        //       "Piezas por Caja",
                        //       "Cantidad",
                        //       "Precio",
                        //       "Total",
                        //     ],
                        //   ],
                        //   body: dataPDFLimpia,
                        //   startY: 45,
                        //   foot: [
                        //     [
                        //       "Totales",
                        //       new Intl.NumberFormat("en-US").format(
                        //         total_cajas
                        //       ),
                        //       "",
                        //       new Intl.NumberFormat("en-US").format(
                        //         totalCantidad
                        //       ),
                        //       "",
                        //       "$" +
                        //         new Intl.NumberFormat("en-US").format(
                        //           total_importe
                        //         ),
                        //     ],
                        //   ],
                        //   showFoot: "lastPage",
                        // });
                        // var att = doc.output("arraybuffer");
                        // var base64File = encode(att);

                        // Envia el Mail
                        // axios.post(
                        //   URL_SEND_MAIL_ATT,
                        //   {
                        //     subject: `Compra ${data.data.idCompras}`,
                        //     email: mailTo,
                        //     fileName: "Compra.pdf",
                        //     att: base64File,
                        //     text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
                        //      <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
                        //      <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
                        //      <tbody>
                        //      <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
                        //      <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Se ha recibido una nueva compra.</tr>
                        //      </tbody>
                        //      <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
                        //      <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
                        //      </table>`,
                        //   },
                        //   {
                        //     headers: {
                        //       Authorization: `Bearer: ${localStorage.getItem(
                        //         "app_token"
                        //       )}`,
                        //     },
                        //   }
                        // );
                        // Termina Mail

                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / i.piezasPorCaja;
        let totalParcial = parseFloat(event.target.value) * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCaja = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  function jalaEmbarque(e) {
    setSelectedEmbarque(e);

    axios
      .get(`${URL_ARTICULOS_EMBARQUES}/embarque/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosEmb = res.data;
        const arrayArt = allArticulosEmb.map((a) => {
          return {
            id: uuidv4(),
            idArticulosEmabrque: a._id,
            articulos: a.articulos[0]._id,
            articulosName: a.articulos[0].codigo,
            pack: a.pack,
            cantidad: a.cantidad,
            piezasPorCaja: a.articulos[0].piezasPorCaja,
            cajas:
              parseFloat(a.cantidad) / parseFloat(a.articulos[0].piezasPorCaja),
            precio: a.articulosOC[0].precio,
            total: a.cantidad * a.articulosOC[0].precio
          };
        });
        setArticulosEmb(allArticulosEmb);
        setInputFields(arrayArt);

        let cant = arrayArt.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);

        let importes = arrayArt.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Entrada</h3>
          <Form onSubmit={saveCompraNueva}>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Embarques</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedEmbarque}
                  required
                  onChange={(e) => jalaEmbarque(e.target.value)}
                >
                  <option value="0">Selecciona un Embarque</option>
                  {embarques
                    .sort((a, b) => (a.idEmbarques < b.idEmbarques ? 1 : -1))
                    .map((a) => {
                      if (a.is_active == "Si" && a.recibido == "No") {
                        return (
                          <option value={a._id}>
                            {a.idEmbarques}
                            {" / "} {a.contenedor}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Almacen</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un almacen</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a._id == AREAS_CEDIS || a._id == AREAS_BOLIVAR){
                        
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Tipo Cambio
                </Label>
                <Input
                  type="number"
                  placeholder="TipoCambio"
                  value={tipoCambio}
                  required
                  onChange={(e) => {
                    setTipoCambio(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Pzas por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio USD</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.idArticulosEmabrque}
                      required
                      disabled
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosEmb
                        .sort((a, b) =>
                          a.articulos[0].codigo > b.articulos[0].codigo ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.articulos[0].codigo}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>


                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cajas.toFixed(2)}
                      onChange={(event) =>
                        handleChangeInputCaja(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="string"
                      value={inputField.piezasPorCaja}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasCreate;
