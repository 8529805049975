import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPendienteTraspasar() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TRASPASOS_SOLICITUD_V2 = process.env.REACT_APP_URL_TRASPASOS_SOLICITUD_V2;
  const URL_ARTICULOS_TSOL_V2 = process.env.REACT_APP_URL_ARTICULOS_TSOL_V2;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [selectedAreaSalida, setSelectedAreaSalida] = useState("");
  const [selectedAreaDestino, setSelectedAreaDestino] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_traspaso, setNumeroTraspaso] = useState("");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [totalCajas, setTotalCajas] = useState(0);

  const [selectedTraspaso, setSelectedTraspaso] = useState("");

  const [articulosPed, setArticulosPed] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [abrirSurtir, setAbrirSurtir] = useState(false);

  const [activo, setActivo] = useState("");
  const [impuestos, setImpuestos] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      cajas: 0,
      piezasPorCaja: 0,
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      costoUnitario: 0
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedAreaSalida(user.areas);
    axios
      .get(`${URL_TRASPASOS_SOLICITUD_V2}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTraspasos = response.data;
        // Array para pagination
        let arrayTabla = allTraspasos
          .sort((a, b) => (a.fechaModificado < b.fechaModificado ? 1 : -1))
          .map((a) => {
            if (user.areas == AREAS_GENERAL) {
              return {
                _id: a._id,
                numero: a.idTraspasosSolicitudV2,
                activo: a.is_active,
                fecha: a.fecha,
                observaciones: a.observaciones,
                total_general: a.total_general,
                total_cantidad: a.total_cantidad,
                status: a.status,
                autorizadoApartado: a.autorizadoApartado,
                autorizadoSurtido: a.autorizadoSurtido,
                idAreaSalida: a.areaSalida[0]._id,
                areaSalida: a.areaSalida[0].name,
                idAreaDestino: a.areaDestino[0]._id,
                areaDestino: a.areaDestino[0].name,
                surtido: a.surtido,
                impuestos: a.impuestos,
                editado: a.editado,
                fechaModificado: a.fechaModificado
              };
            } else if (user.areas == AREAS_CEDIS) {
              if (a.areaSalida[0]._id == AREAS_CEDIS) {
                return {
                  _id: a._id,
                  numero: a.idTraspasosSolicitudV2,
                  activo: a.is_active,
                  fecha: a.fecha,
                  observaciones: a.observaciones,
                  total_general: a.total_general,
                  total_cantidad: a.total_cantidad,
                  status: a.status,
                  autorizadoApartado: a.autorizadoApartado,
                  autorizadoSurtido: a.autorizadoSurtido,
                  idAreaSalida: a.areaSalida[0]._id,
                  areaSalida: a.areaSalida[0].name,
                  idAreaDestino: a.areaDestino[0]._id,
                  areaDestino: a.areaDestino[0].name,
                  surtido: a.surtido,
                  impuestos: a.impuestos,
                  editado: a.editado,
                  fechaModificado: a.fechaModificado
                };
              }
            } else {
              if (a.areaSalida[0]._id == user.areas) {
                return {
                  _id: a._id,
                  numero: a.idTraspasosSolicitudV2,
                  activo: a.is_active,
                  fecha: a.fecha,
                  observaciones: a.observaciones,
                  total_general: a.total_general,
                  total_cantidad: a.total_cantidad,
                  status: a.status,
                  autorizadoApartado: a.autorizadoApartado,
                  autorizadoSurtido: a.autorizadoSurtido,
                  idAreaSalida: a.areaSalida[0]._id,
                  areaSalida: a.areaSalida[0].name,
                  idAreaDestino: a.areaDestino[0]._id,
                  areaDestino: a.areaDestino[0].name,
                  surtido: a.surtido,
                  impuestos: a.impuestos,
                  editado: a.editado,
                  fechaModificado: a.fechaModificado
                };
              }
            } 
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}SurtirCEDIS`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) 
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Traspasos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Traspasos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) 
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoTraspasos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoTraspasos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Ordenes de Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Traspasos",
          email: mailTo,
          fileName: "ListadoTraspasos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Traspasos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idAreaSalida,
    idAreaDestino,
    idPDFOC,
    areaSalida,
    areaDestino,
    autorizadoApartado
  ) {
    setSelectedAreaSalida(idAreaSalida);
    setSelectedAreaDestino(idAreaDestino);

    await axios
      .get(`${URL_ARTICULOS_TSOL_V2}/surtir/${idPDFOC}/${idAreaSalida}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosTraspaso = response.data.AP;
        let allInventarios = response.data.AI;

        let allInventarios2 = allInventarios
          .map((a) => {
            return {
              idArticulo: a.articulos[0]._id,
              articulo: a.articulos[0].codigo + a.articulos[0].nombre,
              cantidadInventario: a.cantidad,
              almacen: a.areas[0].name,
              cantidadTraspaso: 0,
              surtido: 0,
              pendiente_surtir: 0,
              cajas: 0,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataInv = Object.values(allInventarios2);

        let agrupado = dataInv
          // .sort((a, b) => (a.pack > b.pack ? 1 : -1))
          .reduce(function (groups, item) {
            const val = item["idArticulo"];
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              cajas: 0,
            };
            groups[val].articulo = item.articulo;
            groups[val].cantidadTraspaso = item.cantidadTraspaso;
            groups[val].surtido = item.surtido;
            groups[val].pendiente_surtir = item.pendiente_surtir;
            groups[val].cajas = item.cajas;
            groups[val].piezasPorCaja = item.piezasPorCaja;
            return groups;
          }, []);

        let dataAgrupado = Object.values(agrupado);

        let allArticulosTraspaso2 = allArticulosTraspaso
          .map((a) => {
            return {
              articulo: a.articulos[0].codigo + a.articulos[0].nombre,
              idArticulo: a.articulos[0]._id,
              cantidadTraspaso: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
              cajas:
                parseFloat(a.pendiente_surtir) /
                parseFloat(a.articulos[0].piezasPorCaja),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataTraspasos = Object.values(allArticulosTraspaso2);

        // dataTraspasos.map((a) =>
        //   dataAgrupado.map((b) => {
        //     if (a.idArticulo == b.idArticulo) {
        //       a.pack = b.pack;
        //     }
        //     return a;
        //   })
        // );

        let totalTraspaso = dataTraspasos.map((c) => parseFloat(c.cantidadTraspaso));
        let TP = totalTraspaso.reduce((t, total, index) => t + total, 0);

        let totalSurtido = dataTraspasos.map((c) => parseFloat(c.surtido));
        let TS = totalSurtido.reduce((t, total, index) => t + total, 0);

        let totalPendSurtir = dataTraspasos.map((c) =>
          parseFloat(c.pendiente_surtir)
        );
        let TPS = totalPendSurtir.reduce((t, total, index) => t + total, 0);

        let TC = dataTraspasos.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        const data = dataTraspasos
          .sort((a, b) => (a.articulo < b.articulo ? 1 : -1))
          .map((a) => {
            return [
              a.articulo,
              a.piezasPorCaja,
              new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
              new Intl.NumberFormat("en-US").format(a.pendiente_surtir),
            ];
          })
          .sort((a, b) => (a.idArticulo > b.idArticulo ? 1 : -1));
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 15, 15);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 65, 15);
        doc.text(`No. Solicitud:`, 35, 20);
        doc.text(`${numero}`, 65, 20);
        doc.text(`Autorizado:`, 35, 25);
        doc.text(`${autorizadoApartado}`, 65, 25);

        doc.text(`Area Salida:`, 120, 15);
        doc.text(`${areaSalida}`, 150, 15);
        doc.text(`Area Destino:`, 120, 20);
        doc.text(`${areaDestino}`, 150, 20);


        doc.setFontSize(8);
  

        doc.autoTable({
          head: [["Articulo", "Pzs Caja", "Cajas", "Pend Surtir", ]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 50,
          foot: [
            [
              "",
              "Totales",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)),
              new Intl.NumberFormat("en-US").format(TPS),
            ],
          ],
          showFoot: "lastPage",
        });
        
        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Traspaso-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Ultima Mod", field: "fechaModificacion", sortable: true },
    { name: "Almacen Salida", field: "areaSalida", sortable: true },
    { name: "Almacen Destino", field: "areaDestino", sortable: true },
    { name: "PDF", field: "pdf", sortable: false },
    { name: "Surtir", field: "surtir", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.areaSalida.toLowerCase().includes(search.toLowerCase()) ||
          comment.areaDestino.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    let STotal = inputFields.map((c) => parseFloat(c.cantidad));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    try {
      await axios
        .post(
          URL_TRASPASOS_SALIDA,
          {
            fecha,
            observaciones,
            user: user.id,
            areaSalida: selectedAreaSalida,
            areaDestino: selectedAreaDestino,
            total: ST,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_TS,
                  {
                    traspasosSalida: data.data._id,
                    inventarios: a.idInv,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    traspasosSolicitudV2: selectedTraspaso,
                    articulosTraspaso: a.idArtPed,
                    costoUnitario: a.costoUnitario,
                    areaDestino: selectedAreaDestino,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Traspaso Salida",
                          detalle: `${numero_traspaso} ${selectedAreaSalida} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Traspaso Salida",
                      detalle: `${numero_traspaso} ${selectedAreaSalida} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (
          (i.cantInv >= event.target.value &&
            i.pendSurtir >= event.target.value) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          i.total = parseFloat(event.target.value) * parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (
          (i.cantInv >= cantTemp && i.pendSurtir >= cantTemp) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          i.total =
            parseFloat(event.target.value) *
            parseFloat(i.piezasPorCaja) *
            parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inventarios.filter((e) => e.idArticulo == event.target.value).length == 1
    ) {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticuloUnico(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    } else {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    }
  }
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    artPed,
    precio,
    pendSurtir
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    artPed,
    precio,
    pendSurtir,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
        i.cantInv = cantInv;
        i.idInv = idInv;
        i.costoUnitario = costoUnitario
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  // function BuscaPack(id, idInventario) {
  //   if (inputFields.filter((e) => e.idInv == idInventario).length > 0) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "El modelo en esa Pack ya fue capturado!",
  //       showConfirmButton: false,
  //     });
  //     const newInputFields = inputFields.map((i) => {
  //       if (id === i.id) {
  //         i.cantInv = 0;
  //         i.idInv = "";
  //       }
  //       return i;
  //     });
  //     setInputFields(newInputFields);
  //   } else {
  //     inventarios.map((a) => {
  //       if (a._id == idInventario) {
  //         let cantInv = parseFloat(a.cantidad);
  //         let idInv = a._id;
  //         handleChangeInputArticuloPack(id, cantInv, idInv);
  //       }
  //     });
  //   }
  // }

  // const handleChangeInputArticuloPack = (id, cantInv, idInv) => {
  //   const newInputFields = inputFields.map((i) => {
  //     if (id === i.id) {
  //       i.cantInv = parseFloat(cantInv);
  //       i.idInv = idInv;
  //     }
  //     return i;
  //   });
  //   setInputFields(newInputFields);
  //   ActualizaTotales();
  // };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);

    let cantCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TTC = cantCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TTC);
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        let artPed = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a._id;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let precio = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a.precio;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        inventarios.map((a) => {
          if (a.idArticulo == event.target.value) {
            let idArticulo = a.idArticulo;
            let piezasPorCaja = a.piezasPorCaja;
            let costoUnitario = a.costoUnitario
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              artPed,
              precio,
              costoUnitario
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu pedido",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  async function seleccionarTraspaso(
    id,
    numero,
    idAreaSalida,
    idAreaDestino,
    impuestos,
  ) {
    setSelectedTraspaso(id);
    setNumeroTraspaso(numero);
    setSelectedAreaSalida(idAreaSalida);
    setSelectedAreaDestino(idAreaDestino);
    setImpuestos(impuestos);

    if(idAreaSalida == AREAS_CEDIS){
    axios
      .get(`${URL_ARTICULOS_TSOL_V2}/surtir3/${id}/${idAreaSalida}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPed = res.data;
        setArticulosPed(allArticulosPed);
        let arrayInputFields = allArticulosPed
          .map((a) => {
            return {
              id: uuidv4(),
              articulos: a.articulos,
              cantidad: 0,
              cantInv: a.cantInv,
              idInv: a.idInv,
              idArtPed: a.idArtPed,
              cajas: 0,
              piezasPorCaja: a.piezasPorCaja,
              precio: a.precio,
              total: 0,
              pendSurtir: a.pendSurtir,
              articuloNombre: a.articuloNombre,
              cantPed: a.cantPed,
              costoUnitario : a.costoUnitario
            };
          })
          .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);

        let result = allArticulosPed.map((a) => a.articulos);
        setIdsArticulos(result);

        if(dataIF.length > 0){
          setAbrirSurtir(true)
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No hay nada pendiente por surtir!",
          });
        }
        
      })
      .catch((err) => {
        console.log(err);
      })
    }else{
      
    axios
    .get(`${URL_ARTICULOS_TSOL_V2}/surtir3CEDIS/${id}/${idAreaSalida}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosPed = res.data;
      setArticulosPed(allArticulosPed);

      let arrayInputFields = allArticulosPed
        .map((a) => {
          return {
            id: uuidv4(),
            articulos: a.articulos,
            cantidad: 0,
            cantInv: a.cantInv,
            idInv: a.idInv,
            idArtPed: a.idArtPed,
            cajas: 0,
            piezasPorCaja: a.piezasPorCaja,
            precio: a.precio,
            total: 0,
            pendSurtir: a.pendSurtir,
            articuloNombre: a.articuloNombre,
            cantPed: a.cantPed,
            costoUnitario : a.costoUnitario
          };
        })
        .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

      let dataIF = Object.values(arrayInputFields);

      setInputFields(dataIF);

      let result = allArticulosPed.map((a) => a.articulos);
      setIdsArticulos(result);

      if(dataIF.length > 0){
        setAbrirSurtir(true)
      }else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No hay nada pendiente por surtir!",
        });
      }
      
    })
    .catch((err) => {
      console.log(err);
    });
    }


  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_traspasos_salida ? (
        <div className="card col-12">
          {!abrirSurtir ? (
            <div>
              <Row>
                <Col md={8}>
                  {/* <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button> */}
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pendiente Surtir</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pendientes de Traspasar</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
              
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if (a.editado == "Si") {
                      return (
                        <tr style={{ backgroundColor: "orange" }}>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.areaSalida}</td>
                          <td>{a.areaDestino}</td>
                          <td>
                            {user.menu_traspasos_salida ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a.idAreaSalida,
                                      a.idAreaDestino,
                                      a._id,
                                      a.areaSalida,
                                      a.areaDestino,
                                      a.autorizadoApartado
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>
                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarTraspaso(
                                  a._id,
                                  a.numero,
                                  a.idAreaSalida,
                                  a.idAreaDestino,
                                  a.impuestos,
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          </td>
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_TRASPASOS_SOLICITUD_V2}
                              hijo={`${URL_ARTICULOS_TSOL_V2}/baja`}
                            />
                            {"  "}Modificado
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.areaSalida}</td>
                          <td>{a.areaDestino}</td>
                          <td>
                            {user.menu_traspasos_salida ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a.idAreaSalida,
                                      a.idAreaDestino,
                                      a._id,
                                      a.areaSalida,
                                      a.areaDestino,
                                      a.autorizadoApartado
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>
                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarTraspaso(
                                  a._id,
                                  a.numero,
                                  a.idAreaSalida,
                                  a.idAreaDestino,
                                  a.impuestos,
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          </td>
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_TRASPASOS_SOLICITUD_V2}
                              hijo={`${URL_ARTICULOS_TSOL_V2}/baja`}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : (
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Surtir Traspaso {numero_traspaso}</h4>
            
              <Form onSubmit={saveSurtidos}>
                <Row>
                  <Col md={2}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      placeholder="Impuestos"
                      value={impuestos}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos */}

                <Row>
                  <Col md={1}>
                    <Label className="mr-sm-2">Codigo QR</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  {/* <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col> */}
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Traspaso</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pend Surtir</Label>
                  </Col>

                  <Col md={1}>
                    <Label className="mr-sm-2">Inventario</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                </Row>

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="string"
                          value={inputField.articulos}
                          onChange={(event) => {
                            BuscaCodigo(inputField.id, event);
                          }}
                        ></Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          required
                          onChange={(event) => {
                            BuscaArticulo(inputField.id, event);
                          }}
                        >
                          <option value="">Selecciona un Articulo</option>
                          {articulosPed
                            .sort((a, b) =>
                              a.articuloNombre > b.articuloNombre ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a.articulos}>
                                  {a.articuloNombre}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                      {/* <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          disabled
                        />
                      </Col> */}
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantPed"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cant Ped"
                          value={inputField.cantPed}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="pendSurtir"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.pendSurtir}
                          required
                          disabled
                        />
                      </Col>


                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantInv"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cajas"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cajas"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCantidadCajas(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="piezasPorCaja"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Piezas por Caja"
                          value={inputField.piezasPorCaja}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {totalCajas} Cajas {total} Pzas.
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPendienteTraspasar;
