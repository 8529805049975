import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Get from "../../Get";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;

  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [piezasPorCaja, setPiezasPorCaja] = useState(0);
  const [costoUSD, setCostoUSD] = useState(0);
  const [costoMX, setCostoMX] = useState(0);
  const [venta, setVenta] = useState(0);
  const [observaciones, setObservaciones] = useState("NA");
  const [colores, setColores] = useState("NA");
  const [proporcion, setProporcion] = useState("NA");
  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [precioEsp, setPrecioEsp] = useState("");
  const [ano, setAno] = useState("");
  const [EAN, setEAN] = useState("NA");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [lista1, setLista1] = useState(0);
  const [lista2, setLista2] = useState(0);
  const [lista3, setLista3] = useState(0);
  const [lista4, setLista4] = useState(0);

  const [validaBoton, setValidaBoton] = useState(true);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  useEffect(() => {
    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ARTICULOS,
          {
            codigo,
            nombre,
            piezasPorCaja,
            costoUSD,
            costoMX,
            venta,
            observaciones,
            colores,
            proporcion,
            lineas: selectedLinea,
            marcas: selectedMarca,
            familias: selectedFamilia,
            colecciones: selectedColeccion,
            proveedores: selectedProveedor,
            ano,
            EAN,
            precioEsp: "Si",
            lista1,
            lista2,
            lista3,
            lista4,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )


            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Modelo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container">
          <h3 align="center">Nuevo Modelo</h3>
          {/* <Get/> */}
          <Form onSubmit={saveArticulos}>
            <Row>
              <Col md={3}>
                <Label>Numero de Modelo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Piezas Por Caja</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  value={piezasPorCaja}
                  required
                  onChange={(e) => {
                    setPiezasPorCaja(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>EAN</Label>
                <Input
                  type="text"
                  placeholder="EAN"
                  value={EAN}
                  required
                  onChange={(e) => {
                    setEAN(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>FOB USD</Label>
                <Input
                    type="number"
                    min="0"
                    step="any"
                  value={costoUSD}
                  required
                  onChange={(e) => {
                    setCostoUSD(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Costo MX</Label>
                <Input
                    type="number"
                    min="0"
                    step="any"
                  value={costoMX}
                  required
                  onChange={(e) => {
                    setCostoMX(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                    type="number"
                    min="0"
                    step="any"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Observaciones</Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Colores</Label>
                <Input
                  type="text"
                  placeholder="Colores"
                  value={colores}
                  required
                  onChange={(e) => {
                    setColores(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Proporcion</Label>
                <Input
                  type="text"
                  placeholder="Proporcion"
                  value={proporcion}
                  required
                  onChange={(e) => {
                    setProporcion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Linea</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedLinea}
                  required
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Linea</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Marca</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedMarca}
                  required
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Marca</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Familia</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedFamilia}
                  required
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Familia</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccion}
                  required
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Coleccion</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Año</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={ano}
                  required
                  onChange={(e) => {
                    setAno(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Año</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
            </Row>
              <h4>Precios de Venta</h4>
            <Row>
            <Col md={3}>
                <Label>Lista 1</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista1}
                  required
                  onChange={(e) => {
                    setLista1(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 2</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista2}
                  required
                  onChange={(e) => {
                    setLista2(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 3</Label>
                <Input
                    type="number"
                    min="0"
                    step="any"
                  value={lista3}
                  required
                  onChange={(e) => {
                    setLista3(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 4</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista4}
                  required
                  onChange={(e) => {
                    setLista4(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
