import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosSolicitudV2Create() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TRASPASOS_SOLICITUD_V2 = process.env.REACT_APP_URL_TRASPASOS_SOLICITUD_V2;
  const URL_ARTICULOS_TSOL_V2 = process.env.REACT_APP_URL_ARTICULOS_TSOL_V2;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_MIXCALCO = process.env.REACT_APP_AREAS_MIXCALCO;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);
  // const [disponible, setDisponible] = useState([]);
  // const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);

  const [areas, setAreas] = useState([]);
  const [selectedAreaSalida, setSelectedAreaSalida] = useState("");
  const [selectedAreaDestino, setSelectedAreaDestino] = useState("");

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
      cantInv: 0,
      // disponible:0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);


  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });



    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const savePedido = async (event) => {
    event.preventDefault();

    if(selectedAreaDestino == selectedAreaSalida){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Los almacenes de Salida y Destino No pueden ser iguales",
      });
    } else{

    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length;

    try {
      await axios
        .post(
          URL_TRASPASOS_SOLICITUD_V2,
                {
                  fecha,
                  areaDestino: selectedAreaDestino,
                  total_general: parseFloat(total_general).toFixed(2),
                  observaciones,
                  total_cantidad,
                  areaSalida: selectedAreaSalida,
                  subTotal: parseFloat(subTotal).toFixed(2),
                  iva: parseFloat(iva).toFixed(2),
                  impuestos: selectedImpuesto,
                  surtido: "No",
                  autorizadoApartado: "Si",
                  autorizadoSurtido: "Si",
                  editado: "No",
                  fechaModificado:fecha
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_TSOL_V2,
                        {
                          traspasosSolicitudV2: data.data._id,
                          articulos: a.articulos,
                          cantidad: parseFloat(a.cantidad),
                          surtido: 0,
                          pendiente_surtir: parseFloat(a.cantidad),
                          precio: parseFloat(a.precio).toFixed(2),
                          total: parseFloat(a.total).toFixed(2),
                          autorizadoApartado: "Si",
                          autorizadoSurtido: "Si",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Solicitud Traspaso",
                          detalle: `${value._id} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Solicitud Traspaso",
                      detalle: `${value._id} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    } 
}
};

  // function jalaAlmacenSalida(e) {
  //   setSelectedAreaSalida(e);
  //   console.log(e)
  //   toggleProgreso()

  //     axios
  //       .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         const allInventarios = res.data;
  //         setInventarios(allInventarios);

  //         let result = allInventarios.map((a) => a.idArticulo);
  //         setIdsArticulos(result);

  //         let unicos = allInventarios.filter(
  //           (value, index, self) =>
  //             index === self.findIndex((t) => t.idArticulo === value.idArticulo)
  //         );
  //         setArticulosUnicos(unicos);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //       axios
  //       .get(`${URL_INVENTARIOS}DisponibleTraspaso/${e}`, {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       })
  //       .then((response) => {
  //         let allDisponible = response.data;
  //         setDisponible(allDisponible);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
    
  //       setTimeout(()=>{
  //         setModalProgreso(false)
  //       }, 3000)

  //   setInputFields([
  //     {
  //       id: uuidv4(),
  //       articulos: "",
  //       piezasPorCaja: 0,
  //       cajas: 0,
  //       cantidad: 0,
  //       precio: 0,
  //       total: 0,
  //       cantInv: 0,
  //       disponible:0,
  //     },
  //   ]);
  // }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        cantInv: 0,
        // disponible:0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * TG) / 100 +
        TG
    );
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };



  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
    if(event.target.value){
        let cantTemp = parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
              i.cantidad = cantTemp;
              i.cajas = event.target.value
              let totalParcial = cantTemp * i.precio;
              i.total = totalParcial;
      } else{
        i.cajas = event.target.value
      }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
              i.cantidad = event.target.value;
              i.cajas = (parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)).toFixed(2);
              let totalParcial = i.cantidad * i.precio;
              i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let idArticulo = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          handleChangeInputArticulo(id, idArticulo, piezasPorCaja, precio);
        }
      });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let cantInv = parseFloat(a.cantidad);
          handleChangeInputArticuloUnico(
            id,
            cantInv,
          );
        }
      });

      // disponible.map((a) => {
      //   if (a.idArticulo == event.target.value) {
      //     let disponible = a.disponible;
      //     handleChangeInputDispobinle(id, disponible);
      //   }
      // });
    }
  }

  // const handleChangeInputDispobinle = (id, disponible) => {
  //   const newInputFields = inputFields.map((i) => {
  //     if (id === i.id) {
  //       i.disponible = parseFloat(disponible);
  //     }
  //     return i;
  //   });
  //   setInputFields(newInputFields);
  //   ActualizaTotales();
  // };

  const handleChangeInputArticuloUnico = (
    id,
    cantInv,
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantInv = parseFloat(cantInv);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  const handleChangeInputArticulo = (id, idArticulo, piezasPorCaja, precio) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * TG) / 100 +
        TG 
    );
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_salida_create? (
        <div className="card container col-12">
          <h3 align="center">Nueva Solicitud</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>       

            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedAreaSalida}
                onChange={(e) => {
                  setSelectedAreaSalida(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (a._id != AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
              </Input>
            </Col>

            <Col md={2}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                {user.areas == AREAS_GENERAL ? (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={selectedAreaDestino}
                    required
                    onChange={(e) => {
                      setSelectedAreaDestino(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Destino</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if(selectedAreaSalida == AREAS_CEDIS  ){
                        if (a._id != AREAS_GENERAL && a._id != AREAS_MIXCALCO ) {
                          return <option value={a._id}>{a.name}</option>;
                        }} else {
                          if (a._id == AREAS_CEDIS) {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        }
                      })}
                  </Input>
                ) : (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={selectedAreaDestino}
                    required
                    onChange={(e) => {
                      setSelectedAreaDestino(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Destino</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                )}
              </Col>
           

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
      
          </Row>
          <br />

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cajas</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Piezas Por Caja</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>

          </Row>

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                      <option value="">Selecciona un Articulo</option>
                      {articulos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cajas"
                    type="string"
                    value={inputField.cajas}
                    onChange={(event) =>
                      handleChangeInputCajas(inputField.id, event)
                    }
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="piezasPorCaja"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.piezasPorCaja}
                    required
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputCantidad(inputField.id, event)
                    }
                  />
                </Col>
              
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    // tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoTraspasosSolicitud"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

        
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosSolicitudV2Create;
